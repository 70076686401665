import React, { useState, useEffect } from "react";

import "./Main.css";
import { Layout, Menu, Avatar, Badge, Drawer, Button } from "antd";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  ShopOutlined,
  LineChartOutlined,
  UserOutlined,
  TeamOutlined,
  ScheduleOutlined,
  HomeOutlined,
  PoweroffOutlined,
  MessageOutlined,
  PlusCircleOutlined,
  FileTextOutlined,
} from "@ant-design/icons";

import { BrowserRouter as Router, Route, Link } from "react-router-dom";

import LogoFox from "../../assets/images/logoFoxDashboard.svg";
import LogoFox2 from "../../assets/images/logoFoxDashboard2.png";
import Dashboard from "../Dashboard/Dashboard";
import Agendamentos from "../Agendamentos/Agendamentos";
import Colaboradores from "../Colaboradores/Colaboradores";
import Clientes from "../Clientes/Clientes";
import Franquia from "../Franquia/Franquia";
import Unidades from "../Unidades/Unidades";
import Unidade from "../Unidade/Unidade";

import UpdateManager from "../../components/UpdateManager/UpdateManager";
import Colaborador from "../Colaborador/Colaborador";
import Footer from "../../components/Footer/Footer";
import {
  Notificacoes,
  ProgramarEnvio,
  StatusProcesso,
  Listas,
  Lista,
} from "../Notificacoes";
import { RequiredAuth, getLoggedUser } from "../../common/util";

const routes: Array<any> = [
  {
    path: `${process.env.PUBLIC_URL}/main`,
    exact: true,
    sidebar: "Dashboard",
    icon: <LineChartOutlined />,
    main: () => <Dashboard />,
    submenus: [],
    renderInMenu: true,
  },
  {
    path: `${process.env.PUBLIC_URL}/main/notifications`,
    exact: true,
    sidebar: "Notificações",
    icon: <MessageOutlined />,
    main: () => <Notificacoes />,
    submenus: [
      {
        path: `${process.env.PUBLIC_URL}/main/notifications/new`,
        sidebar: "Programar Envio",
        exact: true,
        icon: <PlusCircleOutlined />,
        main: () => <ProgramarEnvio />,
      },
      {
        path: `${process.env.PUBLIC_URL}/main/lists/`,
        sidebar: "Listas de Clientes",
        exact: true,
        icon: <FileTextOutlined />,
        main: () => <Listas />,
      },
    ],
    renderInMenu: true,
  },
  {
    path: `${process.env.PUBLIC_URL}/main/registers/franchise`,
    sidebar: "Franquia",
    exact: true,
    icon: <ShopOutlined />,
    main: () => <Franquia />,
    submenus: [],
    renderInMenu: true,
  },
  {
    path: `${process.env.PUBLIC_URL}/main/lists/:id`,
    sidebar: "Lista de clientes Item",
    exact: true,
    icon: null,
    main: () => <Lista />,
    submenus: [],
    renderInMenu: false,
  },
  {
    path: `${process.env.PUBLIC_URL}/main/registers/franchise/units`,
    sidebar: "Unidades",
    icon: <HomeOutlined />,
    main: () => <Unidades />,
    submenus: [],
    exact: true,
    renderInMenu: true,
  },
  {
    path: `${process.env.PUBLIC_URL}/main/registers/franchise/units/:id`,
    sidebar: "Unidade",
    icon: <HomeOutlined />,
    main: () => <Unidade />,
    submenus: [],
    exact: true,
    renderInMenu: false,
  },
  {
    path: `${process.env.PUBLIC_URL}/main/notifications/:id/status`,
    sidebar: "Status Processo",
    icon: <HomeOutlined />,
    main: () => <StatusProcesso />,
    submenus: [],
    exact: true,
    renderInMenu: false,
  },
  {
    path: `${process.env.PUBLIC_URL}/main/schedules`,
    sidebar: "Agendamentos",
    icon: <ScheduleOutlined />,
    main: () => <Agendamentos />,
    submenus: [],
    renderInMenu: true,
  },
  {
    path: `${process.env.PUBLIC_URL}/main/registers/clients`,
    sidebar: "Clientes",
    exact: true,
    icon: <UserOutlined />,
    main: () => <Clientes />,
    submenus: [],
    renderInMenu: true,
  },
  {
    path: `${process.env.PUBLIC_URL}/main/registers/collaborators`,
    sidebar: "Colaboradores",
    exact: true,
    icon: <TeamOutlined />,
    main: () => <Colaboradores />,
    submenus: [],
    renderInMenu: true,
  },
  {
    path: `${process.env.PUBLIC_URL}/main/units/unit`,
    exact: true,
    main: () => <Unidade />,
    submenus: [],
  },
  {
    path: `${process.env.PUBLIC_URL}/main/registers/collaborators/:id`,
    exact: true,
    main: () => <Colaborador />,
    submenus: [],
  },
];

const { Header, Sider, Content } = Layout;
const { SubMenu } = Menu;
export default function Main(props) {
  RequiredAuth(props);
  const [collapsed, setCollapsed] = useState(true);
  const [updateRegister, setUpdateRegister] = useState(false);
  const [user, setUser] = useState({
    firstName: null,
    lastName: null,
    email: null,
  });

  const [visible, setVisible] = useState(false);

  const onClose = () => {
    setVisible(false);
  };
  const toggle = () => setCollapsed(!collapsed);
  useEffect(() => {
    const userLogged = getLoggedUser();
    setUser(userLogged);
    if (
      !userLogged.firstName ||
      !userLogged.lastName ||
      !userLogged.cpf ||
      !userLogged.email ||
      !userLogged.phone
    ) {
      setUpdateRegister(true);
    }
  }, []);

  function handleLogoff() {
    localStorage.clear();
    props.history.push("/");
  }

  function DrawerUser() {
    return (
      <Drawer
        placement="right"
        closable={false}
        onClose={onClose}
        visible={visible}
        width="300px"
      >
        <div className="profile-user">
          <div>
            <Avatar shape="square" size={95} icon={<UserOutlined />} />
          </div>
          <div style={{ marginLeft: "10px" }}>
            <h4>{`${user.firstName} ${user.lastName}`}</h4>
            <h4>{`${user.email}`}</h4>
            <Button
              type="primary"
              onClick={handleLogoff}
              icon={<PoweroffOutlined />}
            >
              Logoff
            </Button>
          </div>
        </div>
      </Drawer>
    );
  }

  return (
    <Router>
      <DrawerUser />
      <Layout className="container">
        <Sider trigger={null} collapsible collapsed={collapsed} theme="dark">
          <div
            style={{
              background: "#2682d9",
              justifyContent: collapsed ? "center" : "normal",
              display: "flex",
              padding: "12px",
            }}
          >
            <img
              src={collapsed ? LogoFox : LogoFox2}
              alt="Logo Fox"
              width={collapsed ? 38 : 175}
            />
          </div>
          <Menu
            theme="dark"
            defaultSelectedKeys={[routes[0].path]}
            style={{ height: "100%", width: "100%", background: "#2270c1" }}
          >
            {routes.map((route: any) => {
              if (route.submenus.length > 0) {
                return (
                  <SubMenu
                    popupClassName="popup-submenu"
                    key={route.path}
                    title={
                      <Link to={route.path} className="main-link">
                        {route.icon}
                        <span>{route.sidebar}</span>
                      </Link>
                    }
                  >
                    {route.submenus.map((item: any) => (
                      <Menu.Item key={item.path}>
                        <Link to={item.path}>
                          {item.icon}
                          <span>{item.sidebar}</span>
                        </Link>
                      </Menu.Item>
                    ))}
                  </SubMenu>
                );
              } else if (route.sidebar) {
                return (
                  <Menu.Item
                    key={route.path}
                    hidden={!route.renderInMenu}
                    icon={route.icon}
                    style={{ fontSize: "16px" }}
                  >
                    <Link to={route.path}>
                      <span>{route.sidebar}</span>
                    </Link>
                  </Menu.Item>
                );
              }
            })}
          </Menu>
        </Sider>
        <Layout className="site-layout">
          <div style={{ flexDirection: "row", display: "flex" }}>
            <Header
              className="site-layout-background"
              style={{ padding: 0, width: "90%" }}
            >
              {React.createElement(
                collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                {
                  className: "trigger",
                  onClick: toggle,
                }
              )}
            </Header>
            <div className="main-avatar">
              <h4
                style={{
                  alignSelf: "center",
                  marginRight: "3px",
                  marginTop: "5px",
                  color: "gray",
                }}
              >
                Olá,
              </h4>
              <h4
                style={{
                  alignSelf: "center",
                  marginRight: "10px",
                  marginTop: "5px",
                }}
              >
                {user && user.firstName ? user.firstName : ""}
              </h4>
              <div
                style={{
                  alignSelf: "center",
                  display: "flex",
                  cursor: "pointer",
                }}
                onClick={() => setVisible(true)}
              >
                <Badge dot>
                  <Avatar shape="square" icon={<UserOutlined />} />
                </Badge>
              </div>
            </div>
          </div>
          <Content
            className="site-layout-background"
            style={{
              margin: "18px 16px",
              padding: 10,
              minHeight: 280,
              backgroundColor: "#f0f2f5",
              overflow: "auto",
            }}
          >
            <UpdateManager
              updateRegister={updateRegister}
              setModalVisible={setUpdateRegister}
            />
            {routes.map((route: any) => {
              return (
                <>
                  <Route
                    key={route.path}
                    path={route.path}
                    exact={route.exact}
                    strict={route.strict || false}
                    component={route.main}
                  />
                  {route.submenus.map((item: any) => (
                    <Route
                      key={item.path}
                      path={item.path}
                      exact={item.exact}
                      strict={route.strict || false}
                      component={item.main}
                    />
                  ))}
                </>
              );
            })}
          </Content>
        </Layout>
      </Layout>
      <Footer />
    </Router>
  );
}
