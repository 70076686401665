import React, { useEffect, useState, useRef } from "react";
import { Form, Input, Button, Checkbox, message } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import logo from "../../assets/images/logoFox.png";
import "./Login.css";
import { useLazyQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

function Login(props: any) {
  const [user, setUser] = useState({
    email: null,
    password: null,
    remember: false,
  });
  const form = useRef<any>();

  const handleLogin = (values) => {
    setUser({
      email: values.username,
      password: values.password,
      remember: values.remember,
    });
    authManager();
  };

  useEffect(() => {
    let userLogged = localStorage.getItem("loggedUser");
    if (userLogged) {
      const data = JSON.parse(userLogged);

      if (data && data.token) {
        form.current.setFieldsValue({
          username: data.email,
          password: "000000000000",
        });
        message.destroy();
        message.success("Login efetuado!", 3);
        setTimeout(() => {
          props.history.push("/main");
        }, 2000);
      }
    }
    if (loading && called && !data) {
      message.loading("Efetuando login...", 0);
    }
  });

  //#region GraphQL Declarations

  const LOGIN_QUERY = gql`
    query loginManager($managerAuth: ManagerLoginInput!) {
      loginManager(managerAuth: $managerAuth) {
        Id
        firstName
        lastName
        email
        phone
        cpf
        token
        franchises {
          franchisingId
        }
      }
    }
  `;

  const [authManager, { called, loading, data }] = useLazyQuery(LOGIN_QUERY, {
    variables: {
      managerAuth: {
        email: user && user.email ? user.email : "",
        password: user && user.password ? user.password : "",
      },
    },
    fetchPolicy: "no-cache",
    onCompleted: ({ loginManager }) => {
      localStorage.setItem("loggedUser", JSON.stringify(loginManager));
      localStorage.setItem("remember", user?.remember ? "1" : "0");
      setUser(loginManager);
    },
    onError: (error) => {
      message.destroy();
      message.error(error.message.substring(14), 3);
      setUser({
        email: null,
        password: null,
        remember: false,
      });
    },
  });

  //#endregion

  return (
    <div className="container-login">
      <Form
        ref={form}
        name="normal_login"
        className="login-form"
        initialValues={{
          remember: true,
        }}
        onFinish={handleLogin}
      >
        <div className="logo-container">
          <img src={logo} className="logo" alt="logo" />
        </div>
        <Form.Item
          name="username"
          rules={[
            {
              required: true,
              message: "Informe o usuário/e-mail!",
            },
          ]}
        >
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="Usuário/E-mail"
            size="large"
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: "Informe a senha!",
            },
          ]}
        >
          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Senha"
            size="large"
          />
        </Form.Item>
        <Form.Item>
          <Form.Item name="remember" valuePropName="checked" noStyle>
            <Checkbox>Lembrar-me</Checkbox>
          </Form.Item>

          <a className="login-form-forgot" href="">
            Esqueceu a senha
          </a>
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
            size="large"
          >
            Entrar
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default Login;
