import React, { useState, useRef } from "react";
import "./NovaMensagem.css";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { LoadingOutlined } from "@ant-design/icons";
import {
  Modal,
  Form,
  Mentions,
  Input,
  Upload,
  Col,
  Row,
  message as showMessage,
  Button,
} from "antd";
import { RcFile } from "antd/lib/upload";
export default function NovaMensagem({ modalProperties }) {
  const [banner, setBanner] = useState<any>(null);
  const { modalVisible, setModalVisible } = modalProperties;
  const [prefix, setPrefix] = useState("");
  const clientProperties = {
    $: ["nomeCliente", "sobrenomeCliente", "celularCliente", "cpfCliente"],
  };
  const formRef = useRef<any>();
  const UPLOAD_PHOTO = gql`
    mutation uploadBannerMessage($file: Upload!) {
      uploadBannerMessage(file: $file) {
        url
      }
    }
  `;

  const ADICIONAR_MENSAGEM = gql`
    mutation adicionarMensagem($mensagem: MensagemInput!) {
      adicionarMensagem(mensagem: $mensagem) {
        title
        content
        banner
      }
    }
  `;
  const [uploadBanner, { loading }] = useMutation(UPLOAD_PHOTO);
  const [adicionarMensagem, { loading: loadingAddMessage }] = useMutation(
    ADICIONAR_MENSAGEM
  );

  function beforeUpload(file: RcFile) {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    const isLt2M = file.size / 1024 / 1024 < 2;

    if (!isJpgOrPng) {
      showMessage.error("São permitidos somente arquivos de imagem!");
    }
    if (!isLt2M) {
      showMessage.error("O tamanho do banner é limitado em 1MB!");
    }

    return isJpgOrPng && isLt2M;
  }

  function handleAddMessage(values) {
    const { title, message } = values;

    const request = {
      title,
      content: message,
      banner: banner.url,
    };
    adicionarMensagem({
      variables: {
        mensagem: request,
      },
    })
      .then((response) => {
        showMessage.success("Mensagem cadastrada com sucesso!");
        formRef.current.setFieldsValue({
          title: null,
          message: null,
          banner: null,
        });
        setModalVisible(false);
      })
      .catch((onreject) => showMessage.error("Algo deu errado ;("));
  }

  return (
    <Modal
      title="Nova Mensagem"
      visible={modalVisible}
      footer={""}
      className="modal-new-message"
      width={700}
      onCancel={() => setModalVisible(false)}
    >
      <div>
        <Form layout="vertical" onFinish={handleAddMessage} ref={formRef}>
          <Form.Item
            label="Título"
            name="title"
            rules={[
              {
                required: true,
                message: "Informe o título da mensagem",
              },
            ]}
          >
            <Input placeholder="Ex: Promoção imperdível" />
          </Form.Item>
          <Row>
            <Col span={16} className="new-message-col">
              <Form.Item
                label="Mensagem"
                rules={[
                  {
                    required: true,
                    message: "Informe a mensagem",
                  },
                ]}
                name="message"
                help={
                  <p>
                    Para utilizar campos do dinâmicos na mensagem, basta
                    selecionar o campo através do caracter "$"
                  </p>
                }
              >
                <Mentions
                  placeholder={`Digite sua mensagem aqui...
              `}
                  rows={8}
                  prefix={["$"]}
                  onSearch={(_, prefix) => setPrefix(prefix)}
                >
                  {(clientProperties[prefix] || []).map((value) => (
                    <Mentions.Option key={value} value={value}>
                      {value}
                    </Mentions.Option>
                  ))}
                </Mentions>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="banner"
                label="Banner"
                rules={[
                  {
                    required: true,
                    message: "Faça upload do banner",
                  },
                ]}
              >
                <Upload
                  type="drag"
                  style={{ height: 220 }}
                  beforeUpload={beforeUpload}
                  fileList={[]}
                  customRequest={async ({ file }) => {
                    const banner = await uploadBanner({
                      variables: {
                        file: file,
                      },
                    })
                      .then(({ data: { uploadBannerMessage } }) => {
                        return {
                          url: uploadBannerMessage.url,
                        };
                      })
                      .catch((error) => showMessage.error(error.message));

                    setBanner(banner);
                  }}
                >
                  {banner && banner?.url ? (
                    <img
                      src={banner.url}
                      alt="Banner"
                      className="banner-uploaded"
                    />
                  ) : loading ? (
                    <LoadingOutlined size={32} />
                  ) : (
                    <p className="upload-banner-description">
                      Selecione ou arraste o banner para esta área
                    </p>
                  )}
                </Upload>
              </Form.Item>
            </Col>
          </Row>
          <div className="new-message-actions">
            <Button
              onClick={() => setModalVisible(false)}
              style={{ marginRight: 8 }}
            >
              Cancelar
            </Button>
            <Button htmlType="submit" type="primary">
              Salvar
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
}
