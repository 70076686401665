import React, { useState, useReducer, useRef } from "react";
import {
  Steps,
  Button,
  Result,
  Card,
  Col,
  Row,
  Form,
  Input,
  Select,
  Timeline,
  DatePicker,
  Descriptions,
  Badge,
  Tag,
} from "antd";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { CloseCircleFilled } from "@ant-design/icons";
import NovaMensagem from "../NovaMensagem/NovaMensagem";
import "./ProgramarEnvio.css";
import moment from "moment";
import "moment/locale/pt-br";
import gql from "graphql-tag";
import { Link } from "react-router-dom";
moment.locale("pt-br");

export default function ProgramarEnvio() {
  const [current, setCurrent] = useState(0);
  const { Step } = Steps;
  const formRef = useRef<any>();
  const [state, dispatch] = useReducer(reducer, { dates: [] }, init);
  const categoryOptions = [
    {
      value: 1,
      label: "Promoções",
    },
    {
      value: 2,
      label: "Avisos",
    },
    {
      value: 3,
      label: "Novidades",
    },
  ];
  function reducer(state, action) {
    switch (action.type) {
      case "add":
        formRef.current.setFieldsValue({
          executions: [...state.dates, action.payload],
        });
        return { dates: [...state.dates, action.payload] };
      case "remove":
        const newState = state.dates.filter(
          (date) =>
            date.start !== action.payload.start &&
            date.end !== action.payload.end
        );
        formRef.current.setFieldsValue({
          executions: newState,
        });
        return { dates: newState };
      default:
        throw new Error("Invalid Action");
    }
  }
  function init() {
    return { dates: [] };
  }
  function StepOne() {
    const LISTAS_DE_CLIENTE = gql`
      query getClientLists {
        getClientLists {
          id
          name
        }
      }
    `;
    const { data, error, loading } = useQuery(LISTAS_DE_CLIENTE);

    const options = [
      {
        value: 1,
        label: "Ativo",
      },
      {
        value: 2,
        label: "Inativo",
      },
    ];

    return (
      <div>
        <Card title="Informações Gerais" type="inner">
          <Row>
            <Col span={12}>
              <Form.Item
                name="name"
                label="Nome da campanha"
                rules={[
                  {
                    required: true,
                    message: "Nome da campanha é obrigatório",
                  },
                ]}
              >
                <Input size="large" placeholder="Ex: Promoção de verão" />
              </Form.Item>
              <Form.Item
                name="description"
                label="Descrição"
                rules={[
                  {
                    required: true,
                    message: "Descrição da campanha é obrigatório",
                  },
                ]}
              >
                <Input.TextArea
                  placeholder="Descrição"
                  style={{ fontSize: 16, height: 135 }}
                />
              </Form.Item>
            </Col>
            <Col span={12} className="steps-col">
              <Form.Item name="status" label="Status" initialValue={1}>
                <Select options={options} size="large" />
              </Form.Item>
              <Row>
                <Col span={12}>
                  <Form.Item
                    name="category"
                    label="Categoria"
                    rules={[
                      {
                        required: true,
                        message: "Categoria é obrigatório",
                      },
                    ]}
                  >
                    <Select
                      options={categoryOptions}
                      size="large"
                      placeholder="Selecione a Categoria"
                    />
                  </Form.Item>
                  <Form.Item name="tags" label="Tags">
                    <Select mode="tags" size="large" placeholder="Tags" />
                  </Form.Item>
                </Col>
                <Col span={12} className="steps-col">
                  <Form.Item
                    name="lists"
                    label="Listas de envio"
                    rules={[
                      {
                        required: true,
                        message: "Defina as listas de envio",
                      },
                    ]}
                  >
                    <Select
                      mode="tags"
                      options={
                        data?.getClientLists
                          ? data?.getClientLists?.map((list) => {
                              return { value: list.id, label: list.name };
                            })
                          : []
                      }
                      size="large"
                      placeholder="Para quais clientes será enviado ?"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      </div>
    );
  }

  function StepTwo() {
    const [selectedMessage, setSelectedMessage] = useState<any>(null);
    const BUSCAR_MENSAGENS = gql`
      query buscarMensagens {
        buscarMensagens {
          id
          title
          banner
          content
        }
      }
    `;
    const { data, error, loading } = useQuery(BUSCAR_MENSAGENS, {
      pollInterval: 3000,
      partialRefetch: true,
    });

    const [modalVisible, setModalVisible] = useState(false);

    return (
      <div>
        <Card title="Selecione ou crie uma nova mensagem" type="inner">
          <Row>
            <Col span={18}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Selecione a mensagem",
                  },
                ]}
                name="tempSelect"
              >
                <Select
                  size="large"
                  style={{ width: "100%" }}
                  onChange={(value) => {
                    const message = data.buscarMensagens
                      .filter((data) => data.id === value)
                      .shift();
                    formRef.current.setFieldsValue({
                      message: message,
                    });
                    setSelectedMessage(message);
                  }}
                  options={
                    data && data.buscarMensagens
                      ? data.buscarMensagens.map((data) => {
                          return { label: data.title, value: data.id };
                        })
                      : []
                  }
                />
              </Form.Item>
            </Col>
            <Col span={6} className="steps-col">
              <Button
                style={{ width: "100%" }}
                size="large"
                onClick={() => setModalVisible(true)}
              >
                Nova Mensagem
              </Button>
            </Col>
          </Row>
        </Card>
        <Row>
          <Col span={24}>
            {selectedMessage ? (
              <Card title="Mensagem Selecionada" type="inner">
                <Row>
                  <Col span={18}>
                    <Form.Item
                      label="Título"
                      rules={[
                        {
                          required: true,
                          message: "Selecione a mensagem",
                        },
                      ]}
                    >
                      <Input size="large" value={selectedMessage?.title} />
                    </Form.Item>
                    <Form.Item
                      label="Mensagem"
                      rules={[
                        {
                          required: true,
                          message: "Selecione a mensagem",
                        },
                      ]}
                    >
                      <Input.TextArea
                        style={{ fontSize: 16, height: 140 }}
                        value={selectedMessage?.content}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <div className="message-banner">
                      <p className="message-banner-label">Banner</p>
                      <img
                        width={235}
                        src={selectedMessage?.banner}
                        alt="Banner"
                      />
                    </div>
                  </Col>
                </Row>
              </Card>
            ) : (
              <div className="message-empty">
                <p>Nenhuma mensagem selecionada</p>
              </div>
            )}
          </Col>
        </Row>
        <NovaMensagem modalProperties={{ modalVisible, setModalVisible }} />
      </div>
    );
  }
  function StepThree() {
    const [selectedDate, setSelectedDate] = useState<any>(null);
    const date = new Date();
    return (
      <div>
        <Card type="inner" title="Datas de execução">
          <Row>
            <Col span={24}>
              <Row>
                <Col span={20}>
                  <Form.Item
                    name="tempDateRange"
                    rules={[
                      {
                        required: true,
                        message: "Defina o período de execução",
                      },
                    ]}
                  >
                    <DatePicker.RangePicker
                      showTime={{ format: "HH:mm" }}
                      format="DD/MM/YYYY HH:mm"
                      disabledDate={(current) => {
                        return current && current <= moment(date);
                      }}
                      size="large"
                      onCalendarChange={(values) => {
                        if (values?.[0] != null && values?.[1] != null) {
                          const { _d: initial }: any = values[0];
                          const { _d: final }: any = values[1];
                          setSelectedDate({
                            start: initial.getTime().toString(),
                            end: final.getTime().toString(),
                          });
                        }
                      }}
                      style={{ width: "100%" }}
                      locale={{
                        lang: {
                          locale: "pt_BR",
                          placeholder: "Select date",
                          rangePlaceholder: ["Data Inicial", "Data Final"],
                          today: "Hoje",
                          now: "Agora",
                          backToToday: "Voltar para data atual",
                          ok: "Ok",
                          clear: "Limpar",
                          month: "Mês",
                          year: "Ano",
                          timeSelect: "Select time",
                          dateSelect: "Select date",
                          monthSelect: "Choose a month",
                          yearSelect: "Choose a year",
                          decadeSelect: "Choose a decade",
                          yearFormat: "YYYY",
                          dateFormat: "M/D/YYYY",
                          dayFormat: "D",
                          dateTimeFormat: "M/D/YYYY HH:mm:ss",
                          monthFormat: "MMMM",
                          monthBeforeYear: true,
                          previousMonth: "Previous month (PageUp)",
                          nextMonth: "Next month (PageDown)",
                          previousYear: "Last year (Control + left)",
                          nextYear: "Next year (Control + right)",
                          previousDecade: "Last decade",
                          nextDecade: "Next decade",
                          previousCentury: "Last century",
                          nextCentury: "Next century",
                        },
                        timePickerLocale: {
                          placeholder: "Select time",
                        },
                        dateFormat: "DD/MM/YYYY",
                        dateTimeFormat: "DD/MM/YYYY HH:mm:ss",
                        weekFormat: "YYYY-wo",
                        monthFormat: "YYYY-MM",
                      }}
                    />
                  </Form.Item>
                  <div className="execution-days">
                    {state.dates.length > 0 ? (
                      <Timeline>
                        {state.dates.map((date) => (
                          <Timeline.Item
                            className="timeline-date-item"
                            key={date.start}
                          >
                            {
                              <div className="dates-timeline">
                                <p className="dates-timeline-label">
                                  Inicia em:
                                </p>
                                {moment(parseInt(date.start)).format(
                                  "DD/MM/YYYY HH:mm"
                                )}
                                <p className="dates-timeline-label">
                                  Termina em:
                                </p>
                                {moment(parseInt(date.end)).format(
                                  "DD/MM/YYYY HH:mm"
                                )}
                                <Button
                                  type="text"
                                  className="btn-remove-date"
                                  onClick={() =>
                                    dispatch({ type: "remove", payload: date })
                                  }
                                >
                                  <CloseCircleFilled />
                                </Button>
                              </div>
                            }
                          </Timeline.Item>
                        ))}
                      </Timeline>
                    ) : (
                      <p className="empty-date-list">
                        Nenhuma data selecionada
                      </p>
                    )}
                  </div>
                </Col>
                <Col span={4}>
                  <Button
                    size="large"
                    className="btn-add"
                    disabled={
                      selectedDate?.start && selectedDate?.end ? false : true
                    }
                    onClick={() =>
                      dispatch({ type: "add", payload: selectedDate })
                    }
                  >
                    Adicionar
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      </div>
    );
  }

  function StepFour() {
    const [values] = useState(getFormValues(formRef));

    const LISTAS_DE_CLIENTE = gql`
      query getClientLists {
        getClientLists {
          id
          name
        }
      }
    `;
    const { data, loading } = useQuery(LISTAS_DE_CLIENTE);

    function Lists() {
      let lists: Array<string> = [];
      for (const list of values.lists) {
        const item = data.getClientLists
          .filter((listItem) => listItem.id === list)
          .shift();
        if (item) {
          lists.push(item);
        }
      }
      return (
        <>
          {loading ? (
            <p>Carregando...</p>
          ) : (
            lists.map((l: any) => <p key={l.id}>{l.name}</p>)
          )}
        </>
      );
    }

    return (
      <>
        {loading || !values ? (
          <p>Carregando...</p>
        ) : (
          <div>
            <Card type="inner">
              <Descriptions
                title="Resumo do processo de envio"
                bordered
                layout="vertical"
              >
                <Descriptions.Item label="Campanha">
                  {values?.name}
                </Descriptions.Item>

                <Descriptions.Item label="Descrição">
                  {values?.description}
                </Descriptions.Item>
                <Descriptions.Item label="Status">
                  {values?.status === 1 ? (
                    <Badge status="success" text="Ativo" />
                  ) : (
                    <Badge status="warning" text="Inativo" />
                  )}
                </Descriptions.Item>
                <Descriptions.Item label="Categoria">
                  {categoryOptions
                    .filter((category) => category.value === values?.category)
                    .map((c) => (
                      <p key={c.value}>{c.label}</p>
                    ))}
                </Descriptions.Item>
                <Descriptions.Item label="Lista de envio">
                  <Lists />
                </Descriptions.Item>
                <Descriptions.Item label="Tags">
                  <div>
                    {values?.tags?.map((tag: string) => (
                      <Tag
                        key={tag}
                        color={`#${Math.floor(
                          Math.random() * 16777215
                        ).toString(16)}`}
                      >
                        {tag}
                      </Tag>
                    ))}
                  </div>
                </Descriptions.Item>
                <Descriptions.Item label="Período de execução" span={24}>
                  <div>
                    {values?.executions?.map((day) => (
                      <div
                        key={day.start + day.end}
                        className="resume-description-days"
                      >
                        <p className="dates-timeline-label">Inicia em:</p>
                        {moment(parseInt(day.start)).format(
                          "DD/MM/YYYY HH:mm:ss"
                        )}
                        <p className="dates-timeline-label">Termina em:</p>
                        {moment(parseInt(day.end)).format(
                          "DD/MM/YYYY HH:mm:ss"
                        )}
                      </div>
                    ))}
                  </div>
                </Descriptions.Item>
                <Descriptions.Item label="Mensagem">
                  <Row>
                    <Col span={18} className="col-message-description">
                      <div className="resume-description-days">
                        <p className="dates-timeline-label">Título:</p>
                        <p>{values?.message?.title}</p>
                      </div>
                      <div className="resume-description-days">
                        <p className="dates-timeline-label">Mensagem:</p>
                        {values?.message?.content}
                      </div>
                    </Col>
                    <Col span={6} className="col-image-description">
                      <img
                        src={values?.message?.banner}
                        alt="Banner"
                        className="description-banner"
                      />
                    </Col>
                  </Row>
                </Descriptions.Item>
              </Descriptions>
            </Card>
          </div>
        )}
      </>
    );
  }

  const steps = [
    {
      title: "Novo Envio",
      content: <StepOne />,
    },
    {
      title: "Selecionar Mensagem",
      content: <StepTwo />,
    },
    {
      title: "Programar Envio",
      content: <StepThree />,
    },
    {
      title: "Resumo",
      content: <StepFour />,
    },
  ];

  function getFormValues(ref) {
    return (
      ref.current?.getFieldsValue([
        "name",
        "description",
        "status",
        "executions",
        "message",
        "category",
        "lists",
        "tags",
      ]) || {}
    );
  }
  const ADD_NOTIFICATION_TASK = gql`
    mutation addNotificationTask($notificationTask: NotificationTaskInput!) {
      addNotificationTask(notificationTask: $notificationTask) {
        name
      }
    }
  `;

  const [addTask, { loading: loadingAddTask, data, error }] = useMutation(
    ADD_NOTIFICATION_TASK
  );
  function handleAddNewTask() {
    const {
      name,
      description,
      category,
      message,
      tags,
      lists,
      executions,
    } = getFormValues(formRef);

    addTask({
      variables: {
        notificationTask: {
          name: name,
          description: description,
          category: category,
          executions: executions,
          tags: tags,
          message: {
            title: message?.title,
            banner: message?.banner,
            content: message?.content,
            id: message?.id,
          },
          lists: lists,
        },
      },
    })
      .then((data) => console.log(data))
      .catch((error) => console.log(error));
  }

  return (
    <div>
      <Card title="Novo Envio">
        <Form
          layout="vertical"
          ref={formRef}
          name="processoEnvio"
          onFinish={handleAddNewTask}
        >
          <Steps current={current} type="navigation" size="small">
            {steps.map((item) => (
              <Step key={item.title} title={item.title} />
            ))}
          </Steps>
          <div className="steps-content">
            {data ? (
              <Result
                className="result-info"
                status="success"
                title={`A programação de envio "${data?.addNotificationTask.name}" foi agendada com sucesso!`}
                subTitle="Será executada de acordo com os dias definidos nas datas de execução"
              />
            ) : error ? (
              <Result
                className="result-info"
                status="error"
                title={`Algo deu errado...`}
                subTitle={error?.message.substr(14)}
              />
            ) : (
              steps[current].content
            )}
          </div>
          <div className="steps-action">
            {current < steps.length - 1 && (
              <Button
                type="primary"
                onClick={async () => {
                  await formRef.current.validateFields();
                  setCurrent(current + 1);
                }}
              >
                Próximo
              </Button>
            )}
            {current === steps.length - 1 &&
              (data || error ? (
                <Button type="primary">
                  <Link to="/app/main/notifications/">Concluir</Link>
                </Button>
              ) : (
                <Button type="primary" htmlType="submit">
                  Concluir
                </Button>
              ))}
            {current > 0 &&
              (data || error ? null : (
                <Button
                  style={{ margin: "0 8px" }}
                  onClick={() => setCurrent(current - 1)}
                >
                  Voltar
                </Button>
              ))}
          </div>
        </Form>
      </Card>
    </div>
  );
}
