import React, { useEffect, useState } from "react";
import { Modal, Form, Input, Row, Col, Button } from "antd";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import { Store } from "antd/lib/form/interface";
import { getLoggedUser } from "../../common/util";

export default function UpdateManager({ updateRegister, setModalVisible }) {
  const [user, setUser] = useState({
    Id: null,
    firstName: null,
    lastName: null,
    phone: null,
    cpf: null,
    email: null,
  });
  //#region GraphQL Mutation AtualizarGerente
  const ATUALIZAR_GERENTE = gql`
    mutation atualizarGerente($manager: ManagerUpdateInput!) {
      atualizarGerente(manager: $manager) {
        Id
        firstName
        lastName
        phone
        cpf
      }
    }
  `;
  const [atualizarGerente] = useMutation(ATUALIZAR_GERENTE);

  //#endregion

  useEffect(() => {
    const logguedUser = getLoggedUser();
    setUser(logguedUser);
  }, []);

  function handleSubmit(values: Store) {
    const {
      firstName,
      lastName,
      email,
      cpf,
      phone,
      password,
      confirmationPassword,
    } = values;
    if (password !== confirmationPassword) {
      return;
    }
    if (user) {
      atualizarGerente({
        variables: {
          manager: {
            Id: user.Id,
            firstName,
            lastName,
            email,
            cpf,
            phone,
            password,
          },
        },
      })
        .then(({ data: { atualizarGerente } }) => {
          setModalVisible(false);
          const currentUser = user;
          currentUser.firstName = atualizarGerente.firstName;
          currentUser.lastName = atualizarGerente.lastName;
          currentUser.phone = atualizarGerente.phone;
          currentUser.cpf = atualizarGerente.cpf;
          localStorage.clear();
          window.location.replace("/");
        })
        .catch((error) => {
          console.log(error.message);
        });
    }
  }

  return (
    <Modal
      title="Atualizar Cadastro"
      visible={updateRegister}
      closable={false}
      okButtonProps={{ style: { display: "none" } }}
      cancelButtonProps={{ style: { display: "none" } }}
    >
      <Form
        onFinish={handleSubmit}
        initialValues={{
          firstName: user.firstName,
          lastName: user.lastName,
          cpf: user.cpf,
          phone: user.phone,
          email: user.email,
        }}
      >
        <Row>
          <Col span={12}>
            <Form.Item
              name="firstName"
              style={{ marginRight: 5 }}
              rules={[
                {
                  required: true,
                  message: " ",
                },
              ]}
            >
              <Input placeholder="Nome" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="lastName"
              rules={[
                {
                  required: true,
                  message: " ",
                },
              ]}
            >
              <Input placeholder="Sobrenome" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="cpf"
              style={{ marginRight: 5 }}
              rules={[
                {
                  required: true,
                  message: " ",
                },
              ]}
            >
              <Input placeholder="CPF" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="phone"
              rules={[
                {
                  required: true,
                  message: " ",
                },
              ]}
            >
              <Input placeholder="Telefone" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: " ",
                },
              ]}
            >
              <Input placeholder="E-mail" />
            </Form.Item>
          </Col>

          <Col span={12} style={{ paddingRight: 5 }}>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Defina uma nova senha",
                },
              ]}
            >
              <Input placeholder="Nova Senha" type="password" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="confirmationPassword"
              rules={[
                {
                  required: true,
                  message: "Confirme a nova senha",
                },
              ]}
            >
              <Input placeholder="Confirme a  Senha" type="password" />
            </Form.Item>
          </Col>
        </Row>
        <Button
          style={{
            width: "100%",
            fontWeight: "bolder",
          }}
          type="primary"
          size="large"
          htmlType="submit"
        >
          ATUALIZAR CADASTRO
        </Button>
      </Form>
    </Modal>
  );
}
