import React from "react";
import { useLocation } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import groupBy from "lodash/groupBy";
import gql from "graphql-tag";
import { Card, Row, Col, Table, Tag, Progress, Timeline, Tabs } from "antd";
import "./StatusProcesso.css";
import {
  CheckCircleOutlined,
  SyncOutlined,
  ClockCircleOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import moment from "moment";
export default function StatusProcesso() {
  let {
    state: { id },
  } = useLocation<any>();

  const NOTIFICATION_TASK = gql`
    query notificationTask($id: ID!) {
      notificationTask(id: $id) {
        id
        name
        description
        category
        status
        progress
        executions {
          start
          end
        }
        messages {
          id
          title
          to
          messageId
          body
          createAt
          updateAt
          status
        }
      }
    }
  `;

  const { data, loading } = useQuery(NOTIFICATION_TASK, {
    pollInterval: 1000,
    partialRefetch: true,
    variables: {
      id,
    },
  });

  const messages = groupBy(data?.notificationTask.messages, (m) => {
    const date = moment(m.updateAt).format("DD/MM/YYYY");
    return date;
  });

  const categoryOptions = [
    {
      value: 1,
      label: "Promoções",
    },
    {
      value: 2,
      label: "Avisos",
    },
    {
      value: 3,
      label: "Novidades",
    },
  ];

  let groupMessages: any = [],
    item;

  for (let type in messages) {
    item = {};
    item.type = type;
    item.messages = messages[type];
    groupMessages.push(item);
  }

  return (
    <div>
      <Card title={`Campanha ${data?.notificationTask?.name || ""}`}>
        <Row>
          <Col span={12}>
            <div className="status-col-task" style={{ marginRight: 10 }}>
              <p className="status-task-title">Resumo</p>
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                }}
              >
                <div style={{ width: "50%" }}>
                  <p style={{ fontWeight: 600 }}>Categoria</p>
                  <p>
                    {
                      categoryOptions
                        .filter(
                          (category) =>
                            category.value == data?.notificationTask?.category
                        )
                        .shift()?.label
                    }
                  </p>
                </div>
                <div style={{ width: "50%", marginLeft: "125px" }}>
                  <p style={{ fontWeight: 600 }}>Status</p>
                  {data?.notificationTask?.status ===
                  NotificationTaskStatus.scheduled ? (
                    <Tag
                      icon={<ClockCircleOutlined />}
                      className="tag-status-task"
                      color="default"
                    >
                      AGENDADO
                    </Tag>
                  ) : data?.notificationTask?.status ===
                    NotificationTaskStatus.processing ? (
                    <Tag
                      icon={<SyncOutlined spin />}
                      className="tag-status-task"
                      color="processing"
                    >
                      PROCESSANDO
                    </Tag>
                  ) : data?.notificationTask?.status ===
                    NotificationTaskStatus.finished ? (
                    <Tag
                      icon={<CheckCircleOutlined />}
                      className="tag-status-task"
                      color="success"
                    >
                      FINALIZADO
                    </Tag>
                  ) : (
                    <Tag
                      icon={<MinusCircleOutlined />}
                      className="tag-status-task"
                      color="default"
                    >
                      CANCELADO
                    </Tag>
                  )}
                </div>
              </div>
              <div>
                <div style={{ paddingTop: "10px" }}>
                  <p style={{ fontWeight: 600 }}>Datas de processamento</p>
                  <Timeline>
                    {data?.notificationTask?.executions.map((item) => (
                      <Timeline.Item key={item.start + item.end}>
                        {`${moment(parseInt(item.start)).format(
                          "DD/MM/YY HH:mm"
                        )} até ${moment(parseInt(item.end)).format(
                          "DD/MM/YY HH:mm"
                        )}`}
                      </Timeline.Item>
                    ))}
                  </Timeline>
                </div>
              </div>
            </div>
          </Col>
          <Col span={12}>
            <div className="status-col-task" style={{ marginRight: 10 }}>
              <div>
                <p className="status-task-title">Processamento</p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "20px",
                }}
              >
                <div
                  style={{
                    minWidth: "40%",

                    alignSelf: "center",
                  }}
                >
                  <Progress
                    type="circle"
                    width={175}
                    percent={
                      data?.notificationTask?.status ==
                        NotificationTaskStatus.processing ||
                      data?.notificationTask?.status ===
                        NotificationTaskStatus.finished
                        ? data?.notificationTask?.progress
                        : 0
                    }
                  />
                </div>
                <div
                  style={{
                    minWidth: "60%",
                    alignSelf: "center",
                    paddingLeft: "10px",
                  }}
                >
                  <p style={{ fontWeight: 600 }}>Enviados</p>
                  <Progress
                    percent={Math.ceil(
                      (data?.notificationTask?.messages?.filter(
                        (message) => message.status === "ok"
                      ).length /
                        data?.notificationTask.messages?.length) *
                        100
                    )}
                  />
                  <p style={{ fontWeight: 600 }}>Falhou</p>
                  <Progress
                    percent={Math.ceil(
                      data?.notificationTask?.messages?.filter(
                        (message) =>
                          message.status !== null && message.status !== "ok"
                      ).length
                        ? (data?.notificationTask?.messages?.filter(
                            (message) =>
                              message.status !== null && message.status !== "ok"
                          ).length /
                            data?.notificationTask.messages?.length) *
                            100
                        : 0
                    )}
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Card title="Notificações">
              {groupMessages?.length > 0 ? (
                <Tabs tabPosition="left" defaultActiveKey="1">
                  {groupMessages.map((group) => (
                    <Tabs.TabPane tab={group.type} key={group.type}>
                      <Table
                        size="small"
                        bordered={true}
                        loading={loading}
                        dataSource={loading ? [] : group.messages}
                        columns={[
                          {
                            title: "Título",
                            dataIndex: "title",
                          },
                          {
                            title: "Conteúdo",
                            dataIndex: "body",
                          },
                          {
                            title: "Status",
                            dataIndex: "status",
                            render: (value) =>
                              value == "ok" ? (
                                <Tag color="success">ENVIADO</Tag>
                              ) : value ? (
                                <Tag color="error">FALHA</Tag>
                              ) : (
                                <Tag color="default">AGENDADO</Tag>
                              ),
                          },
                          {
                            title: "Data de envio",
                            dataIndex: "updateAt",
                            render: (value) =>
                              value
                                ? moment(value).format("DD/MM/YY HH:mm")
                                : "",
                          },
                        ]}
                      />
                    </Tabs.TabPane>
                  ))}
                </Tabs>
              ) : (
                <div className="empty-message-container">
                  <p>Nenhuma mensagem foi processada até o momento...</p>
                </div>
              )}
            </Card>
          </Col>
        </Row>
      </Card>
    </div>
  );
}

enum NotificationTaskStatus {
  scheduled = 1,
  processing = 2,
  finished = 3,
  canceled = 4,
}
