import React from "react";
import { Chart, registerShape, Axis, Interval, Coordinate } from "bizcharts";
import { Row, Col, Skeleton, Table } from "antd";
import { PieChartOutlined } from "@ant-design/icons";
import gql from "graphql-tag";
import { getLoggedUser } from "../../../common/util";
import { useQuery } from "@apollo/react-hooks";

const sliceNumber = 0.01;

registerShape("interval", "sliceShape", {
  draw(cfg, container) {
    const points: any = cfg.points;
    let path: any = [];
    path.push(["M", points[0].x, points[0].y]);
    path.push(["L", points[1].x, points[1].y - sliceNumber]);
    path.push(["L", points[2].x, points[2].y - sliceNumber]);
    path.push(["L", points[3].x, points[3].y]);
    path.push("Z");

    path = this["parsePath"](path);

    return container.addShape("path", {
      attrs: {
        fill: cfg.color,
        path: path,
      },
    });
  },
});

export default function ServicosMaisConsumidos() {
  //#region GraphQL Declaration
  const QTY_AGENDAMENTOS_MES = gql`
    query servicosMaisAgendados($franchisingId: ID!) {
      servicosMaisAgendados(franchisingId: $franchisingId) {
        servicos {
          servico
          qty
        }
      }
    }
  `;

  const loggedUser = getLoggedUser();
  const franchisingId = loggedUser.franchises
    .map((f) => f.franchisingId)
    .shift();
  const { loading, data } = useQuery(QTY_AGENDAMENTOS_MES, {
    variables: {
      franchisingId,
    },
    pollInterval: 30000,
  });
  //#endregion

  return (
    <>
      {!loading && data ? (
        <div
          style={{
            background: "white",
            marginRight: "10px",
            minHeight: "100%",
            padding: "25px",
            borderRadius: "3px",
          }}
        >
          <p>Serviços mais consumidos</p>
          <Row>
            <Col span={24}>
              <Chart
                data={
                  !loading && data ? data?.servicosMaisAgendados?.servicos : []
                }
                height={350}
                autoFit
              >
                <Coordinate type="theta" radius={0.8} innerRadius={0.75} />
                <Axis visible={false} />
                <Interval
                  adjust="stack"
                  position="qty"
                  color={{
                    fields: ["servico"],
                    values: [
                      "#5b8ff9",
                      "#5ad8a6",
                      "#5d7092",
                      "#f6bd16",
                      "#e86452",
                    ],
                  }}
                  shape="sliceShape"
                />
              </Chart>
            </Col>
            <Col span={24}>
              <div style={{ marginTop: "14px" }}>
                <Table
                  dataSource={data.servicosMaisAgendados.servicos}
                  showSorterTooltip={false}
                  showHeader={false}
                  bordered={true}
                  pagination={false}
                  columns={[
                    { key: "servico", dataIndex: "servico" },
                    { key: "qty", dataIndex: "qty" },
                  ]}
                />
              </div>
            </Col>
          </Row>
        </div>
      ) : loading ? (
        <div
          style={{ background: "white", padding: "25px", marginRight: "10px" }}
        >
          <Skeleton
            active
            paragraph={{
              rows: 24,
            }}
          />
        </div>
      ) : (
        <div
          style={{
            background: "white",
            marginRight: "10px",
            minHeight: "100%",
            padding: "25px",
            display: "flex",
            borderRadius: "3px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ margin: "30px" }}>
            <PieChartOutlined
              style={{
                color: "#ddd",
                fontSize: "80px",
                display: "flex",
                marginBottom: "20px",
                justifyContent: "center",
              }}
            />
            <p
              style={{
                textAlign: "center",
                color: "gray",
                fontWeight: "lighter",
                fontSize: "16px",
              }}
            >
              Não há dados suficientes para gerar os resultados
            </p>
          </div>
        </div>
      )}
    </>
  );
}
