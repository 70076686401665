import React, { Dispatch, SetStateAction } from "react";
import Modal from "antd/lib/modal/Modal";
import { Descriptions, Table } from "antd";
import { currencyFormater } from "../common/util";
import moment from "moment";

export interface AgendamentoDetalhesProps {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  agendamento: any;
  cliente?: any;
}

export default function AgendamentoDetalhes(props: AgendamentoDetalhesProps) {
  function onClose() {
    props.setVisible(false);
  }

  return (
    <Modal
      title="Agendamento"
      visible={props.visible}
      style={{ top: 10 }}
      okButtonProps={{ hidden: true }}
      cancelButtonProps={{ hidden: true }}
      onCancel={onClose}
    >
      <Descriptions bordered>
        <Descriptions.Item label="Colaborador" span={10}>
          <a
            style={{ textTransform: "capitalize" }}
          >{`${props.agendamento?.profissional.Sequencial} - ${props.agendamento?.profissional.Nome}`}</a>
        </Descriptions.Item>
        <Descriptions.Item label="Cliente" span={10}>
          {`${props.cliente?.firstName} ${props.cliente?.lastName}`}
        </Descriptions.Item>

        <Descriptions.Item label="Data" span={10}>
          {moment(parseInt(props.agendamento?.DataHoraInicial)).format("LLL")}
        </Descriptions.Item>
        <Descriptions.Item label="Unidade" span={10}>
          {props.agendamento?.unidade?.Nome}
        </Descriptions.Item>
        <Descriptions.Item label="Valor" span={10}>
          {currencyFormater(
            props.agendamento?.servico.PrecoVendaCliente,
            "BRL"
          )}
        </Descriptions.Item>
      </Descriptions>
      <h1 style={{ fontSize: 16, marginTop: 30 }}>Serviços</h1>
      <Table dataSource={[props.agendamento?.servico]}>
        <Table.Column title="Nome" dataIndex="Nome" />
        <Table.Column
          title="Valor"
          dataIndex="PrecoVendaCliente"
          render={(PrecoVendaCliente) =>
            currencyFormater(PrecoVendaCliente, "BRL")
          }
        />
        <Table.Column title="Quantidade" render={() => 1} />
      </Table>
    </Modal>
  );
}
