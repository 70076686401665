import React, { CSSProperties, useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import {
  Row,
  Table,
  Drawer,
  Button,
  Descriptions,
  Badge,
  Col,
  Card,
  Avatar,
  Empty,
} from "antd";
import { Link } from "react-router-dom";
import Tag from "antd/es/tag";
import AgendamentoDetalhes from "../../components/AgendamentoDetalhes";
import { getLoggedUser, currencyFormater } from "../../common/util";
import { UserOutlined } from "@ant-design/icons";
import moment from "moment";
import "moment/locale/pt-br";

class ClientesStyle {
  static titulo: CSSProperties = {
    fontSize: 50,
  };
  static table: CSSProperties = {
    width: "100%",
    minHeight: "500px",
  };
  static drawerSubTitle: CSSProperties = {
    fontWeight: "bold",
    fontSize: 16,
    marginTop: 30,
    marginBottom: 10,
  };
}

function ClienteDetalhes({
  clienteSelecionado,
  setClienteSelecionado,
  setAgendamento,
  setAgendamentoVisible,
  franchisingId,
}) {
  function onClose() {
    setClienteSelecionado(undefined);
  }

  //#region GraphQL Declaration buscarAgendamentosPorUsuario
  const BUSCAR_AGENDAMENTOS = gql`
    query buscarAgendamentosPorUsuario($registerId: ID!) {
      buscarAgendamentosPorUsuario(registerId: $registerId) {
        Id
        Descricao
        DataHoraInicial
        DataHoraFinal
        profissional {
          Id
          Sequencial
          Nome
        }
        servico {
          Id
          PrecoVendaCliente
          Nome
        }
        unidade {
          Id
          Nome
        }
      }
    }
  `;
  const register = clienteSelecionado
    ? clienteSelecionado.registers
        .filter((r) => r.franchisingId === franchisingId)
        .shift()
    : {};
  const { data, loading } = useQuery(BUSCAR_AGENDAMENTOS, {
    variables: {
      registerId: register.registerId,
    },
  });

  //#endregion

  return (
    <Drawer
      title="Cliente"
      placement="right"
      closable={false}
      onClose={onClose}
      visible={clienteSelecionado !== undefined}
      width="75%"
    >
      <Col
        style={{
          justifyContent: "center",
          alignSelf: "center",
          alignItems: "center",
          alignContent: "center",
        }}
      >
        <Descriptions
          title={`${clienteSelecionado?.firstName} ${clienteSelecionado?.lastName}`}
          bordered
        >
          <Descriptions.Item label="Celular" span={2}>
            {clienteSelecionado?.phone}
          </Descriptions.Item>
          <Descriptions.Item label="Email">
            {clienteSelecionado?.email}
          </Descriptions.Item>

          <Descriptions.Item label="CPF" span={2}>
            {clienteSelecionado?.cpf}
          </Descriptions.Item>
          <Descriptions.Item label="Status">
            <Badge
              status={clienteSelecionado?.valid ? "success" : "error"}
              text={clienteSelecionado?.valid ? "Ativo" : "Inativo"}
            />
          </Descriptions.Item>
        </Descriptions>

        <h1 style={ClientesStyle.drawerSubTitle}>Últimos agendamentos</h1>
        <Table
          dataSource={!loading && data ? data.buscarAgendamentosPorUsuario : []}
          loading={loading}
          locale={{
            emptyText: "Este cliente não fez agendamentos até o momento",
          }}
        >
          <Table.Column
            title="Colaborador"
            dataIndex="profissional"
            render={(profissional) => {
              return (
                <Link
                  to={{
                    pathname: `${
                      process.env.PUBLIC_URL
                    }/main/registers/collaborators/${profissional.Id.toLowerCase()}`,
                    state: {
                      id: profissional.Id,
                    },
                  }}
                  style={{ textTransform: "capitalize" }}
                >
                  {`${profissional.Sequencial} - ${profissional.Nome}`}
                </Link>
              );
            }}
          />
          <Table.Column
            title="Serviço"
            dataIndex="servico"
            render={(servico) => {
              return (
                <Tag color={"blue"} key={servico}>
                  {servico.Nome.toUpperCase()}
                </Tag>
              );
            }}
          />
          <Table.Column
            title="Valor"
            dataIndex="servico"
            render={(servico) => (
              <h6 style={{ fontSize: 13, color: "green" }}>
                {currencyFormater(servico.PrecoVendaCliente, "BRL")}
              </h6>
            )}
          />
          <Table.Column
            title="Data"
            dataIndex="DataHoraInicial"
            render={(DataHoraInicial) =>
              `${moment(parseInt(DataHoraInicial)).format("DD/MM/YYYY")}`
            }
          />
          <Table.Column
            title="Horário"
            dataIndex="DataHoraInicial"
            render={(DataHoraInicial) =>
              `${moment(parseInt(DataHoraInicial)).format("LT")}`
            }
          />
          <Table.Column
            title="Unidade"
            dataIndex="unidade"
            render={(unidade) => unidade.Nome}
          />
          <Table.Column
            render={(record) => (
              <Button
                type="link"
                onClick={() => {
                  setAgendamento(record);
                  setAgendamentoVisible(true);
                }}
              >
                Ver
              </Button>
            )}
          />
        </Table>
      </Col>
    </Drawer>
  );
}

export default function Clientes() {
  const [clienteSelecionado, setClienteSelecionado] = useState(undefined);
  const [agendamentoVisble, setAgendamentoVisible] = useState<boolean>(false);
  const [agendamento, setAgendamento] = useState(undefined);
  const Id = getLoggedUser().franchises.map((f) => f.franchisingId);
  const [franchisingId] = useState(Id[0]);
  const [limit] = useState(10);
  const [offset, setOffset] = useState(0);

  //#region GraphQL Query buscarClientes

  const BUSCAR_CLIENTES = gql`
    query buscarClientes($franchisingId: ID!, $limit: Int!, $offset: Int!) {
      buscarClientes(
        franchisingId: $franchisingId
        limit: $limit
        offset: $offset
      ) {
        users {
          firstName
          lastName
          valid
          phone
          email
          imageProfile
          cpf
          registers {
            registerId
            franchisingId
          }
        }
        qty
      }
    }
  `;

  const { loading, data } = useQuery(BUSCAR_CLIENTES, {
    variables: {
      franchisingId: franchisingId,
      limit: limit,
      offset: offset,
    },
  });

  //#endregion

  return (
    <Card title={<h1>Clientes</h1>} style={{ borderRadius: "3px" }}>
      <Row>
        <AgendamentoDetalhes
          visible={agendamentoVisble}
          setVisible={setAgendamentoVisible}
          agendamento={agendamento}
          cliente={clienteSelecionado}
        />

        <Table
          style={ClientesStyle.table}
          dataSource={loading ? [] : data?.buscarClientes?.users}
          pagination={{
            total: loading ? 0 : data?.buscarClientes?.qty,
            onChange: (page, pageSize = 0) => {
              setOffset(page * pageSize - limit);
            },
          }}
          loading={loading}
          locale={{
            emptyText: (
              <Empty
                description={
                  <p style={{ margin: "12px" }}>Não ha clientes para exibir</p>
                }
              />
            ),
          }}
        >
          <Table.Column
            dataIndex="imageProfile"
            render={(imageProfile) => {
              return (
                <Avatar size={52} src={imageProfile} icon={<UserOutlined />} />
              );
            }}
          />
          <Table.Column title="Nome" dataIndex="firstName" />
          <Table.Column title="Sobrenome" dataIndex="lastName" />
          <Table.Column title="Celular" dataIndex="phone" />
          <Table.Column title="E-mail" dataIndex="email" />
          <Table.Column title="CPF" dataIndex="cpf" />
          <Table.Column
            render={(record) => (
              <Button
                type="link"
                onClick={() => {
                  setClienteSelecionado(record);
                }}
              >
                Ver
              </Button>
            )}
          />
        </Table>
        <ClienteDetalhes
          clienteSelecionado={clienteSelecionado}
          setClienteSelecionado={setClienteSelecionado}
          setAgendamento={setAgendamento}
          setAgendamentoVisible={setAgendamentoVisible}
          franchisingId={franchisingId}
        />
      </Row>
    </Card>
  );
}
