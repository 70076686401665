import React, { useState } from "react";
import {
  Card,
  Table,
  Form,
  Input,
  Col,
  Row,
  Select,
  Empty,
  Badge,
  Button,
  message,
} from "antd";
import Loading from "../../../components/Loading/Loading";
import { useLocation } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { maskPhone } from "../../../common/util";

export default function Lista() {
  let {
    state: { id, franchisingId },
  } = useLocation<any>();
  const [offset, setOffset] = useState(0);
  const [limit] = useState(10);

  //#region GraphQL Queries and Mutations

  const UPDATE_LIST = gql`
    mutation updateClientList($list: ClientListUpdateInput!) {
      updateClientList(list: $list) {
        id
      }
    }
  `;

  const [updateList] = useMutation(UPDATE_LIST);

  const GET_CLIENT_LIST = gql`
    query getClientsByListId($id: ID!, $limit: Int!, $offset: Int!) {
      getClientsByListId(id: $id, limit: $limit, offset: $offset) {
        users {
          Id
          firstName
          lastName
          phone
          valid
          registers {
            sequential
            franchisingId
          }
        }
        qty
      }
    }
  `;
  const GET_LIST = gql`
    query getClientList($id: ID!) {
      getClientList(id: $id) {
        id
        name
        clients
        franchisingId
        status
        category
      }
    }
  `;
  const {
    data: responseClientList,
    loading: loadingClientList,
    error: errorClientList,
  } = useQuery(GET_LIST, {
    variables: {
      id,
    },
  });

  const { data, loading, error } = useQuery(GET_CLIENT_LIST, {
    variables: {
      id,
      limit: limit,
      offset: offset,
    },
  });
  //#endregion

  //#region Local Functions
  async function handleUpdateClientList(values) {
    const { name, category, status } = values;
    await updateList({
      variables: {
        list: {
          id: id,
          category,
          status,
          name,
        },
      },
    })
      .then((data) => message.success("A lista foi atualizada"))
      .catch((error) => {
        message.error("Algo deu errado");
      });
  }
  //#endregion
  const options = [
    {
      value: 1,
      label: "Ativo",
    },
    {
      value: 2,
      label: "Inativo",
    },
  ];

  const categoryOptions = [
    {
      value: 1,
      label: "Promoções",
    },
    {
      value: 2,
      label: "Avisos",
    },
    {
      value: 3,
      label: "Novidades",
    },
  ];
  return (
    <div>
      {loadingClientList ? (
        <Loading />
      ) : (
        <Form
          layout="vertical"
          onFinish={(values) => handleUpdateClientList(values)}
        >
          <Card title={`Lista ${responseClientList?.getClientList?.name}`}>
            <Row>
              <Col span={24}>
                <Form.Item
                  label="Nome"
                  name="name"
                  initialValue={responseClientList?.getClientList?.name}
                >
                  <Input size="large" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Form.Item
                  name="category"
                  label="Categoria"
                  initialValue={responseClientList?.getClientList?.category}
                >
                  <Select options={categoryOptions} size="large" />
                </Form.Item>
              </Col>
              <Col span={12} style={{ paddingLeft: 5 }}>
                <Form.Item
                  name="status"
                  label="Status"
                  initialValue={responseClientList?.getClientList?.status}
                >
                  <Select options={options} size="large" />
                </Form.Item>
              </Col>
            </Row>

            <Card type="inner" title="Clientes">
              <Table
                bordered={true}
                size="middle"
                loading={loading}
                dataSource={data?.getClientsByListId?.users}
                columns={[
                  {
                    title: "Sequencial",
                    width: 100,
                    render: (_, record) =>
                      record?.registers
                        .filter(
                          (r) => r.franchisingId === franchisingId.toUpperCase()
                        )
                        .shift()?.sequential,
                  },
                  {
                    title: "Nome",
                    render: (_, record) =>
                      `${record?.firstName} ${record?.lastName}`,
                  },
                  {
                    title: "Phone",
                    dataIndex: "phone",
                    render: (value) => `+55 ${maskPhone(value.substr(3))}`,
                  },
                  {
                    title: "Status",
                    dataIndex: "valid",
                    render: (value) => (
                      <Badge
                        text={value ? "Ativo" : "Inativo"}
                        status={value ? "success" : "processing"}
                      />
                    ),
                  },
                ]}
                pagination={{
                  total: data?.getClientsByListId?.qty,
                  onChange: (page, pageSize = 0) => {
                    setOffset(page * pageSize - limit);
                  },
                }}
                locale={{
                  emptyText: (
                    <Empty
                      description="Não há clientes até o momento..."
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                    />
                  ),
                }}
              />
            </Card>
            <div
              style={{
                display: "flex",
                paddingTop: 25,
                height: 55,
                alignContent: "center",
                float: "right",
              }}
            >
              <Button
                type="primary"
                style={{ marginRight: 10 }}
                htmlType="submit"
              >
                SALVAR
              </Button>
              <Button>CANCELAR</Button>
            </div>
          </Card>
        </Form>
      )}
    </div>
  );
}
