import React from "react";

import { BrowserRouter, Route } from "react-router-dom";

import Login from "./pages/Login/Login";
import Main from "./pages/Main/Main";

export default function Routes() {
  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Route path="/" exact component={Login} />
      <Route path="/main" component={Main} />
    </BrowserRouter>
  );
}
