import React from "react";
import { Card, Table, Badge, Button } from "antd";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { Link } from "react-router-dom";
export default function Listas() {
  const GET_LISTS = gql`
    query getClientLists {
      getClientLists {
        id
        name
        clients
        franchisingId
        status
        category
      }
    }
  `;

  const { data, error, loading } = useQuery(GET_LISTS);
  const categoryOptions = [
    {
      value: 1,
      label: "Promoções",
    },
    {
      value: 2,
      label: "Avisos",
    },
    {
      value: 3,
      label: "Novidades",
    },
  ];
  return (
    <div>
      <Card title="Listas de envio">
        <Table
          loading={loading}
          bordered={true}
          dataSource={data?.getClientLists}
          columns={[
            {
              title: "Lista",
              dataIndex: "name",
            },
            {
              title: "Categoria",
              dataIndex: "category",
              render: (value) =>
                categoryOptions.filter((c) => c.value == value).shift()?.label,
            },
            {
              title: "Status",
              dataIndex: "status",
              render: (value) => (
                <Badge
                  status={value == 1 ? "success" : "default"}
                  text={value == 1 ? "Ativo" : "Inativo"}
                />
              ),
            },
            {
              title: "Clientes",
              dataIndex: "clients",
              render: (value) => value?.length || 0,
            },
            {
              title: "Ações",
              dataIndex: "id",
              width: 120,
              render: (value, record) => (
                <Button type="link">
                  <Link
                    to={{
                      pathname: `${process.env.PUBLIC_URL}/main/lists/${value}`,
                      state: {
                        id: value,
                        franchisingId: record.franchisingId,
                      },
                    }}
                  >
                    ACESSAR
                  </Link>
                </Button>
              ),
            },
          ]}
        />
      </Card>
    </div>
  );
}
