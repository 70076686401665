import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/react-hooks";
import ImgCrop from "antd-img-crop";
import gql from "graphql-tag";
import {
  Card,
  Upload,
  Modal,
  Form,
  Input,
  Tooltip,
  Row,
  Col,
  Table,
  Tag,
  Space,
  Button,
  notification,
  message,
} from "antd";
import Loading from "../../components/Loading/Loading";
import { TwitterPicker } from "react-color";
import Barcode from "react-barcode";
import { Link } from "react-router-dom";

import { PlusOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { getLoggedUser } from "../../common/util";
import { RcFile } from "antd/lib/upload";

// import { Container } from './styles';
function getBase64(file: File): Promise<any> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export default function Franquia() {
  const history = useHistory();
  const [cardBackgroundColor, setCardBackgroundColor] = useState(null);
  const [cardBorderColor, setCardBorderColor] = useState(null);
  const [cardFontColor, setCardFontColor] = useState(null);

  const [key, setKey] = useState("tab1");
  const tabs = [
    {
      key: "tab1",
      tab: "Gerais",
    },
    {
      key: "tab3",
      tab: "Unidades",
    },
  ];

  const contentList: any = {
    tab1: <Formulario />,
    tab2: <UploadBanners />,
    tab3: <TableUnidades />,
  };

  //#region GraphQL Declarations

  const FRANQUIA_QUERY = gql`
    query franquia($filtro: FranquiaFiltro!) {
      franquia(filtro: $filtro) {
        Id
        Logo
        Nome
        Ativa
        Site
        Descricao
        unidades {
          Id
          Nome
          TelefoneFixo
          TelefoneCelular
          Endereco {
            cep
            rua
            numero
            bairro
            complemento
          }
          Status
        }
        Banners {
          url
        }
        Cartao {
          Logo
          Cor
          Background
          Borda
        }
      }
    }
  `;
  const loggedUser = getLoggedUser();
  const franchiseId = loggedUser.franchises.map((f) => f.franchisingId);
  const { loading, error, data } = useQuery(FRANQUIA_QUERY, {
    variables: { filtro: { Id: franchiseId[0] } },
  });

  //#endregion

  function UploadBanners() {
    //#region GraphQL Mutation
    const UPLOAD_PHOTO = gql`
      mutation uploadBanner($file: Upload!) {
        uploadBanner(file: $file) {
          url
        }
      }
    `;
    const [uploadBanner] = useMutation(UPLOAD_PHOTO);

    const REMOVER_BANNER = gql`
      mutation removerBanner($banners: [BannerInput!]!) {
        removerBanner(banners: $banners) {
          Id
          Nome
        }
      }
    `;
    const [removerBanner] = useMutation(REMOVER_BANNER);

    //#endregion

    const { Banners } = data.franquia || {};

    for (const banner of Banners) {
      banner.uid = Math.random();
      banner.name = "Logo";
      banner.status = "done";
    }

    //#region States and Params
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState("");
    const [previewTitle, setPreviewTitle] = useState("");
    const [fileUploadList, setFileUploadList] = useState(Banners);
    //#endregion
    const handleChange = ({ file, fileList }: any) => {
      const isLt1M = file.size / 1024 / 1024 < 1;
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";

      if (isJpgOrPng && isLt1M && fileUploadList.length <= 5) {
        return setFileUploadList(fileList);
      } else {
        return;
      }
    };
    const handleCancel = () => setPreviewVisible(false);
    const handlePreview = async (file: any) => {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }

      setPreviewImage(file.url || file.preview);
      setPreviewVisible(true);
      setPreviewTitle(
        file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
      );
    };
    const uploadButton = (
      <div>
        <PlusOutlined />
        <div className="ant-upload-text">Upload</div>
      </div>
    );

    function beforeUpload(file: RcFile) {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;
      const uploadLimit = fileUploadList.length <= 5;

      if (!isJpgOrPng) {
        message.error("São permitidos somente arquivos de imagem!");
      }
      if (!isLt2M) {
        message.error("O tamanho do banner é limitado em 1MB!");
      }
      if (!uploadLimit) {
        message.error("Uma franquia pode ter até 5 banners!");
      }
      return isJpgOrPng && isLt2M && uploadLimit;
    }

    return (
      <div className="clearfix">
        <ImgCrop aspect={4 / 3}>
          <Upload
            accept=".jpg,.png,.jpeg"
            listType="picture-card"
            fileList={fileUploadList}
            onPreview={handlePreview}
            beforeUpload={beforeUpload}
            onChange={handleChange}
            onRemove={(file) => {
              const files = fileUploadList.filter((f) => f.uid !== file.uid);
              const banners = files.map((file) => {
                return { url: file.url };
              });

              removerBanner({
                variables: {
                  banners,
                },
              }).then(({ data: { removerBanner } }) => {
                message.success(
                  `Banner selecionado foi removido da franquia ${removerBanner.Nome}`
                );
                setFileUploadList(files);
              });
            }}
            customRequest={async ({ file }) => {
              if (fileUploadList.length < 5) {
                const responseUpload = await uploadBanner({
                  variables: {
                    file: file,
                  },
                }).then(({ data: { uploadBanner } }) => {
                  return {
                    uid: Math.random(),
                    name: file.name,
                    url: uploadBanner.url,
                    status: "done",
                  };
                });
                setFileUploadList([...fileUploadList, responseUpload]);
              } else {
                setFileUploadList([
                  ...fileUploadList,
                  {
                    uid: Math.random(),
                    name: file.name,
                    status: "error",
                    url: "",
                  },
                ]);
              }
            }}
          >
            {fileUploadList.length >= 5 ? null : uploadButton}
          </Upload>
        </ImgCrop>
        <Modal
          visible={previewVisible}
          title={previewTitle}
          footer={null}
          onCancel={handleCancel}
        >
          <img alt="example" style={{ width: "100%" }} src={previewImage} />
        </Modal>
      </div>
    );
  }

  function UploadLogo({ logo }) {
    //#region GraphQL Mutation
    const UPLOAD_LOGO = gql`
      mutation uploadLogo($file: Upload!) {
        uploadLogo(file: $file) {
          url
        }
      }
    `;
    const [uploadLogo] = useMutation(UPLOAD_LOGO);
    //#endregion

    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState("");
    const [previewTitle, setPreviewTitle] = useState("");
    const [fileUploadList, setFileUploadList] = useState([
      {
        uid: "-1",
        size: 0,
        name: "Logo",
        type: "image/png",
        url: logo,
      },
    ]);

    const handleCancel = () => setPreviewVisible(false);
    const handlePreview = async (file: any) => {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }

      setPreviewImage(file.url || file.preview);
      setPreviewVisible(true);
      setPreviewTitle(
        file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
      );
    };

    return (
      <div>
        <ImgCrop aspect={1 / 1} shape="rect" zoom={true} modalWidth={1000}>
          <Upload
            listType="picture-card"
            fileList={fileUploadList}
            customRequest={({ file }) => {
              uploadLogo({
                variables: {
                  file,
                },
              }).then(({ data: { uploadLogo } }) => {
                message.success(`Logo da franquia foi atualizada`);
                setFileUploadList([
                  {
                    uid: "-1",
                    size: 0,
                    name: "Logo",
                    type: "image/png",
                    url: uploadLogo.url,
                  },
                ]);
              });
            }}
            onPreview={handlePreview}
            onRemove={() => setFileUploadList([])}
          >
            {fileUploadList.length === 0 ? "Logo Franquia" : null}
          </Upload>
        </ImgCrop>
        <Modal
          visible={previewVisible}
          title={previewTitle}
          footer={null}
          onCancel={handleCancel}
        >
          <img alt="example" style={{ width: "100%" }} src={previewImage} />
        </Modal>
      </div>
    );
  }

  function UploadLogoCartao({ cartao }) {
    //#region GraphQL Mutation
    const UPLOAD_LOGO_CARTAO = gql`
      mutation uploadLogoCartao($file: Upload!) {
        uploadLogoCartao(file: $file) {
          url
        }
      }
    `;
    const [UploadLogoCartao] = useMutation(UPLOAD_LOGO_CARTAO);
    //#endregion

    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState("");
    const [previewTitle, setPreviewTitle] = useState("");
    const [fileUploadList, setFileUploadList] = useState([
      {
        uid: "-1",
        size: 0,
        name: "Logo",
        type: "image/png",
        url: cartao.Logo || "",
      },
    ]);

    const handleCancel = () => setPreviewVisible(false);
    const handlePreview = async (file: any) => {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }

      setPreviewImage(file.url || file.preview);
      setPreviewVisible(true);
      setPreviewTitle(
        file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
      );
    };

    return (
      <div>
        <ImgCrop aspect={2.35 / 1} shape="rect" zoom={true} modalWidth={1000}>
          <Upload
            listType="picture-card"
            fileList={fileUploadList}
            customRequest={({ file }) => {
              UploadLogoCartao({
                variables: {
                  file,
                },
              }).then(({ data: { uploadLogoCartao } }) => {
                message.success(`Logo do Cartão foi atualizada`);
                setFileUploadList([
                  {
                    uid: "-1",
                    size: 0,
                    name: "Logo",
                    type: "image/png",
                    url: uploadLogoCartao.url,
                  },
                ]);
              });
            }}
            onPreview={handlePreview}
            onRemove={() => setFileUploadList([])}
          >
            {fileUploadList.length === 0 ? "Logo Cartão" : null}
          </Upload>
        </ImgCrop>
        <Modal
          visible={previewVisible}
          title={previewTitle}
          footer={null}
          onCancel={handleCancel}
        >
          <img alt="example" style={{ width: "100%" }} src={previewImage} />
        </Modal>
      </div>
    );
  }

  function TableUnidades() {
    const { unidades } = data.franquia || {};

    const columns: any = [
      {
        title: "Nome",
        dataIndex: "Nome",
        render: (text: React.ReactNode, record) => (
          <Link
            to={{
              pathname: `${
                process.env.PUBLIC_URL
              }/main/registers/franchise/units/${record.Id.toLowerCase()}`,
              state: { id: record.Id },
            }}
          >
            {record.Nome}
          </Link>
        ),
      },
      {
        title: "Endereço",
        className: "column-money",
        dataIndex: "Endereco",
        render: (text) =>
          text && text.rua && text.numero ? (
            <>{`${text.rua}, ${text.numero}`}</>
          ) : (
            <>N/A</>
          ),
      },
      {
        title: "Status",
        dataIndex: "Status",
        render: (text: React.ReactNode) => (
          <Tag color={text === 1 ? "green" : "red"}>
            {text === 1 ? "Online" : "Offline"}
          </Tag>
        ),
      },
    ];

    return (
      <Table
        columns={columns}
        dataSource={unidades}
        bordered
        title={() => "Unidades"}
      />
    );
  }

  function ColorPicker({ setState, value }) {
    return (
      <TwitterPicker
        onChangeComplete={(color) => setState(color.hex)}
        color={value}
      />
    );
  }

  function Cartao() {
    const { franquia } = data || {};
    const { Cartao } = franquia || {};

    return (
      <div
        style={{
          backgroundColor: cardBackgroundColor
            ? cardBackgroundColor
            : Cartao.Background,
          width: "380px",
          height: "240px",
          borderRadius: "10px",
          borderWidth: "2px",
          borderStyle: "solid",
          color: cardFontColor ? cardFontColor : Cartao.Cor,
          borderColor: cardBorderColor ? cardBorderColor : Cartao.Borda,
        }}
      >
        <div
          style={{
            lineHeight: 0.7,
            padding: 30,
          }}
        >
          <p style={{ fontWeight: "bold" }}>1001</p>
          <p>Cliente</p>
          <p style={{ fontWeight: "bold" }}>JOÃO DA SILVA</p>
          <p>Nome</p>
          <p style={{ fontWeight: "bold", textTransform: "uppercase" }}>
            {franquia.Nome}
          </p>
          <p>Franquia</p>
          <div
            style={{
              backgroundColor: "white",
              height: "50px",
              borderRadius: "10px",
              textAlign: "center",
            }}
          >
            <Barcode
              value="97802"
              format="CODE39"
              displayValue={false}
              height={30}
              width={2.5}
            />
          </div>
          <div
            style={{
              display: "flex",
              position: "absolute",
              marginTop: "-200px",
              marginLeft: "210px",
            }}
          >
            <img src={Cartao.Logo} alt="logo-cartao" width="120" />
          </div>
        </div>
      </div>
    );
  }

  function Formulario() {
    const { franquia } = data || {};

    //#region GraphQL Mutation Declaration
    const ATUALIZAR_FRANQUIA = gql`
      mutation atualizarFranquia($franquia: AtualizarFranquiaInput!) {
        atualizarFranquia(franquia: $franquia) {
          Nome
          Descricao
        }
      }
    `;

    const [atualizarFranquia] = useMutation(ATUALIZAR_FRANQUIA);
    //#endregion

    function handleSubmit(values) {
      const { nome, descricao } = values;
      const franquiaRequest = {
        Id: franchiseId[0],
        Nome: nome,
        Descricao: descricao,
        Cartao: {
          Cor: cardFontColor || franquia.Cartao.Cor,
          CorBorda: cardBorderColor || franquia.Cartao.Borda,
          CorFundo: cardBackgroundColor || franquia.Cartao.Background,
        },
      };

      atualizarFranquia({
        variables: {
          franquia: franquiaRequest,
        },
      })
        .then(({ data: { atualizarFranquia } }) => {
          message.success(
            `A Franquia ${atualizarFranquia.Nome} foi atualizada!`
          );
        })
        .catch((error) => console.log(error));
    }

    return (
      <Form onFinish={handleSubmit}>
        <Row>
          <Col md={8} style={{ paddingRight: "10px" }}>
            <Card
              title={
                <div
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <p style={{ paddingTop: 10 }}>Informações Gerais</p>
                  <Tooltip
                    style={{
                      lineBreak: 'loose'
                    }}
                    title={`
                  -> Logo deverá ser no aspect ratio 4:3 (quadrado), com a resolução mínima de 400x400.
                  -> Informar o Nome da Franquia.
                  -> Informar a Descrição da Franquia.
                  `}
                  >
                    <InfoCircleOutlined style={{ color: "#0693E3" }} />
                  </Tooltip>
                </div>
              }
              headStyle={{ fontSize: "14px" }}
              style={{ borderColor: "#ddd" }}
            >
              <Row>
                <Col span={8}>
                  <UploadLogo logo={franquia?.Logo} />
                </Col>
                <Col md={16}>
                  <Form.Item
                    name="nome"
                    initialValue={franquia.Nome}
                    rules={[{ required: true, message: "Nome é obrigatório" }]}
                  >
                    <Input placeholder="Nome" size="large" />
                  </Form.Item>
                  <Form.Item
                    name="descricao"
                    initialValue={franquia.Descricao}
                    rules={[
                      { required: true, message: "Descrição é obrigatório" },
                    ]}
                  >
                    <Input.TextArea placeholder="Descrição" />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
            <Card
              title={
                <div
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <p style={{ paddingTop: 10 }}>Banners</p>
                  <Tooltip title={`Banners deverão ser no aspect ratio 4:3 (quadrado), com resolução mínima de 400x400.`}>
                    <InfoCircleOutlined style={{ color: "#0693E3" }} />
                  </Tooltip>
                </div>
              }
              headStyle={{ fontSize: "14px" }}
              style={{
                borderColor: "#ddd",
                marginTop: "10px",
                height: "390px",
              }}
            >
              <UploadBanners />
            </Card>
          </Col>
          <Col xs={16}>
            <Card
              title={
                <div
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <p style={{ paddingTop: 10 }}>Cartão</p>
                  <Tooltip
                    title={`Logo do cartão deverá ser no aspect ratio 16:9 (retangular), com resolução mínima de 600x200, considere margem de corte.`}
                  >
                    <InfoCircleOutlined style={{ color: "#0693E3" }} />
                  </Tooltip>
                </div>
              }
              style={{ padding: "5px", width: "100%", borderColor: "#ddd" }}
              headStyle={{ fontSize: "14px" }}
            >
              <Row>
                <Col lg={10}>
                  <Space direction="vertical">
                    <UploadLogoCartao cartao={data.franquia.Cartao} />
                    <div>
                      <h3>Cor de fundo</h3>
                      <ColorPicker
                        setState={setCardBackgroundColor}
                        value={cardBackgroundColor || { hex: "" }}
                      />
                    </div>

                    <div>
                      <h3>Cor da fonte</h3>
                      <ColorPicker
                        setState={setCardFontColor}
                        value={cardFontColor || { hex: "" }}
                      />
                    </div>

                    <div>
                      <h3>Cor da borda</h3>
                      <ColorPicker
                        setState={setCardBorderColor}
                        value={cardBorderColor || { hex: "" }}
                      />
                    </div>
                  </Space>
                </Col>
                <Col xl={12} md={8}>
                  <Space>
                    <Card
                      title="Pré Visualização"
                      headStyle={{ fontSize: "12px" }}
                    >
                      <Cartao />
                    </Card>
                  </Space>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col span={20}></Col>
          <Col span={4} style={{ marginTop: "30px", textAlign: "end" }}>
            <Space>
              <Button type="primary" size="large" htmlType="submit">
                SALVAR
              </Button>
              <Button
                type="ghost"
                size="large"
                onClick={() => history.push("/main")}
              >
                CANCELAR
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
    );
  }

  return (
    <>
      {loading ? (
        <Loading />
      ) : error ? (
        notification.error({
          message: "Erro Inesperado",
          description: error.message,
          duration: 0,
        })
      ) : (
        <Card
          style={{
            width: "100%",
            boxShadow: "5px 10px 18px #ddd",
            backgroundColor: "#fbfbfb",
            marginBottom: "30px",
          }}
          title={<h1>Atualizar Cadastro</h1>}
          tabList={tabs}
          activeTabKey={key}
          onTabChange={(key) => setKey(key)}
        >
          {contentList[key]}
        </Card>
      )}
    </>
  );
}
