import React, { useState } from "react";
import { Row, Table, Tag, Col, Button, Empty, Card } from "antd";

import { Link } from "react-router-dom";
import AgendamentoDetalhes from "../../components/AgendamentoDetalhes";

import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { getLoggedUser, currencyFormater } from "../../common/util";
import moment from "moment";

export default function Agendamentos() {
  const [agendamentoVisble, setAgendamentoVisible] = useState<boolean>(false);
  const [agendamento, setAgendamento] = useState(undefined);
  const [clienteSelecionado, setClienteSelecionado] = useState(undefined);
  const Id = getLoggedUser().franchises.map((f) => f.franchisingId);
  const [franchisingId] = useState(Id[0]);
  const [limit] = useState(10);
  const [offset, setOffset] = useState(0);

  //#region GraphQL Declaration

  const BUSCAR_AGENDAMENTOS_POR_FRANQUIA = gql`
    query buscarAgendamentos($franchisingId: ID!, $limit: Int!, $offset: Int!) {
      buscarAgendamentos(
        franchisingId: $franchisingId
        limit: $limit
        offset: $offset
      ) {
        agendamentos {
          Id
          Descricao
          DataHoraInicial
          DataHoraFinal
          profissional {
            Id
            Sequencial
            Nome
          }
          servico {
            Id
            PrecoVendaCliente
            Nome
          }
          cliente {
            firstName
            lastName
            registers {
              sequential
              registerId
              franchisingId
            }
          }
          unidade {
            Id
            Nome
          }
        }
        qty
      }
    }
  `;

  const { data, loading } = useQuery(BUSCAR_AGENDAMENTOS_POR_FRANQUIA, {
    variables: {
      franchisingId,
      limit: limit,
      offset: offset,
    },
  });

  //#endregion

  return (
    <Row gutter={10}>
      <AgendamentoDetalhes
        visible={agendamentoVisble}
        setVisible={setAgendamentoVisible}
        agendamento={agendamento}
        cliente={clienteSelecionado}
      />

      <Col span={24}>
        <Card title={<h1>Agendamentos</h1>}>
          <Table
            dataSource={
              !loading && data ? data.buscarAgendamentos.agendamentos : []
            }
            locale={{
              emptyText: (
                <Empty
                  description={
                    <p style={{ margin: "12px" }}>
                      Ainda não foi feito nenhum agendamento pelo App
                    </p>
                  }
                />
              ),
            }}
            loading={loading}
            pagination={{
              pageSize: 10,
              total: !loading && data ? data.buscarAgendamentos.qty : 0,
              onChange: (page, pageSize = 0) => {
                setOffset(page * pageSize - limit);
              },
            }}
          >
            <Table.Column
              title="Cliente"
              dataIndex="cliente"
              render={(cliente) => {
                const { sequential } = cliente.registers
                  .filter((r) => r.franchisingId === franchisingId)
                  .shift();
                return `${sequential} - ${cliente.firstName} ${cliente.lastName}`;
              }}
            />
            <Table.Column
              title="Colaborador"
              dataIndex="profissional"
              render={(profissional) => {
                return (
                  <Link
                    style={{ textTransform: "capitalize" }}
                    to={`/main/collaborators/collaborator?id=${profissional.Id.toLowerCase()}`}
                  >
                    {`${profissional.Sequencial} - ${profissional.Nome}`}
                  </Link>
                );
              }}
            />
            <Table.Column
              title="Serviço"
              dataIndex="servico"
              render={(servico) => {
                return (
                  <Tag color={"blue"} key={servico}>
                    {servico.Nome.toUpperCase()}
                  </Tag>
                );
              }}
            />
            <Table.Column
              title="Valor"
              dataIndex="servico"
              render={(servico) => (
                <h6 style={{ fontSize: 13, color: "green" }}>
                  {currencyFormater(servico.PrecoVendaCliente, "BRL")}
                </h6>
              )}
            />
            <Table.Column
              title="Data"
              dataIndex="DataHoraInicial"
              render={(data) => moment(parseInt(data)).format("DD/MM/YYYY")}
            />
            <Table.Column
              title="Horario"
              dataIndex="DataHoraInicial"
              render={(data) => moment(parseInt(data)).format("LT")}
            />
            <Table.Column
              title="Unidade"
              dataIndex="unidade"
              render={(unidade) => unidade.Nome}
            />

            <Table.Column
              render={(record) => (
                <Button
                  type="link"
                  onClick={() => {
                    setAgendamento(record);
                    setClienteSelecionado(record.cliente);
                    setAgendamentoVisible(true);
                  }}
                >
                  Ver
                </Button>
              )}
            />
          </Table>
        </Card>
      </Col>
    </Row>
  );
}
