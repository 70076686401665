import React from "react";
import { Chart, Line, Point } from "bizcharts";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { getLoggedUser } from "../../../common/util";
import { Skeleton } from "antd";

export default function AgendamentosMensais() {
  //#region GraphQL Declaration
  const QTY_AGENDAMENTOS_MES = gql`
    query quantidadeAgendamentosMes($franchisingId: ID!) {
      quantidadeAgendamentosMes(franchisingId: $franchisingId) {
        meses {
          mes
          qty
        }
      }
    }
  `;

  const loggedUser = getLoggedUser();
  const franchisingId = loggedUser.franchises
    .map((f) => f.franchisingId)
    .shift();
  const { loading, data } = useQuery(QTY_AGENDAMENTOS_MES, {
    variables: {
      franchisingId,
    },
    pollInterval: 30000,
  });
  //#endregion

  return (
    <>
      {!loading && data ? (
        <div
          style={{
            background: "white",
            padding: "25px",
            borderRadius: "3px",
            height: 330,
          }}
        >
          <p>Agendamentos </p>
          <Chart
            padding={[10, 20, 50, 40]}
            autoFit
            height={250}
            data={data.quantidadeAgendamentosMes?.meses || []}
            scale={{ value: { min: 0 } }}
          >
            <Line position="mes*qty" />
            <Point position="mes*qty" />
          </Chart>
        </div>
      ) : (
        <div
          style={{ background: "white", padding: "25px", marginRight: "10px" }}
        >
          <Skeleton
            active
            paragraph={{
              rows: 7,
            }}
          />
        </div>
      )}
    </>
  );
}
