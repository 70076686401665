import React, { CSSProperties, useState, useEffect } from "react";
import {
  Col,
  Button,
  Table,
  Tag,
  Row,
  Descriptions,
  Avatar,
  Empty,
} from "antd";
import AgendamentoDetalhes from "../../components/AgendamentoDetalhes";
import { useLocation } from "react-router-dom";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import cepPromise from "cep-promise";
import Loading from "../../components/Loading/Loading";
import { UserOutlined } from "@ant-design/icons";
import { currencyFormater } from "../../common/util";
import moment from "moment";
import "moment/locale/pt-br";

class ColaboradorStyle {
  static container: CSSProperties = {
    backgroundColor: "white",
    borderRadius: 5,
    padding: 20,
  };
  static header: CSSProperties = {
    marginTop: 30,
  };
  static subTitulo: CSSProperties = {
    fontSize: 20,
    color: "#222",
    marginLeft: 10,
    marginBottom: 20,
  };
  static divisor: CSSProperties = {
    height: 1,
    backgroundColor: "#ddd",
    marginLeft: 30,
    marginRight: 30,
    marginTop: 10,
  };
  static faturamentoLabel: CSSProperties = {
    fontSize: 17,
    marginRight: 10,
  };
  static faturamento: CSSProperties = {
    color: "green",
    fontSize: 17,
    fontWeight: "bold",
  };
  static fotoContainer: CSSProperties = {
    width: 250,
    height: 250,
  };
  static foto: CSSProperties = {
    width: "100%",
    borderRadius: 110,
  };
}

function Dados({ colaborador, endereco }) {
  return (
    <Col span={19}>
      <Descriptions bordered column={{ xxl: 4 }}>
        <Descriptions.Item label="Nome Completo" span={10}>
          {colaborador.Nome}
        </Descriptions.Item>
        <Descriptions.Item label="CPF" span={10}>
          {colaborador.CPF}
        </Descriptions.Item>
        <Descriptions.Item label="Cidade" span={2}>
          {endereco.city}
        </Descriptions.Item>
        <Descriptions.Item label="Bairro">
          {endereco.neighborhood}
        </Descriptions.Item>
        <Descriptions.Item label="Rua" span={2}>
          {endereco.street}
        </Descriptions.Item>
        <Descriptions.Item label="Número" span={10}>
          {colaborador.Endereco?.Numero}
        </Descriptions.Item>
        <Descriptions.Item label="Adicional" span={10}>
          {colaborador.Endereco?.Complemento}
        </Descriptions.Item>
      </Descriptions>
    </Col>
  );
}

function Agendamentos({
  setAgendamento,
  setAgendamentoVisible,
  setCliente,
  agendamentos,
  loading,
  colaborador,
}) {
  return (
    <Col>
      <Row style={ColaboradorStyle.header}>
        <Col span={20}>
          <h1 style={ColaboradorStyle.subTitulo}>Últimos Agendamentos</h1>
        </Col>
      </Row>
      <Table
        dataSource={agendamentos}
        loading={loading}
        locale={{
          emptyText: (
            <Empty
              description={
                <p style={{ margin: "25px" }}>
                  Este colaborador não possui agendamentos feitos via app
                </p>
              }
            />
          ),
        }}
      >
        <Table.Column
          title="Cliente"
          dataIndex="cliente"
          render={(cliente) => {
            const { sequential } = cliente.registers
              .filter((r) => r.franchisingId === colaborador.IdFranquia)
              .shift();
            return `${sequential} - ${cliente.firstName} ${cliente.lastName}`;
          }}
        />
        <Table.Column
          title="Serviço"
          dataIndex="servico"
          render={(servico) => {
            return <Tag color={"blue"}>{servico.Nome.toUpperCase()}</Tag>;
          }}
        />
        <Table.Column
          title="Valor"
          dataIndex="servico"
          render={(servico) => (
            <h6 style={{ fontSize: 13, color: "green" }}>
              {currencyFormater(servico.PrecoVendaCliente, "BRL")}
            </h6>
          )}
        />
        <Table.Column
          title="Data"
          dataIndex="DataHoraInicial"
          render={(data) => moment(parseInt(data)).format("DD/MM/YYYY")}
        />
        <Table.Column
          title="Horário"
          dataIndex="DataHoraInicial"
          render={(data) => moment(parseInt(data)).format("LT")}
        />
        <Table.Column
          render={(record) => (
            <Button
              type="link"
              onClick={() => {
                setCliente(record.cliente);
                setAgendamento(record);
                setAgendamentoVisible(true);
              }}
            >
              Ver
            </Button>
          )}
        />
      </Table>
    </Col>
  );
}

function Foto({ imageProfile = "" }) {
  return (
    <div style={{ padding: "30px" }}>
      <Avatar size={200} src={imageProfile} icon={<UserOutlined />} />
    </div>
  );
}

export default function Colaborador() {
  const [agendamentoVisble, setAgendamentoVisible] = useState<boolean>(false);
  const [agendamento, setAgendamento] = useState(undefined);
  const [address, setAddress] = useState({});
  const [cliente, setCliente] = useState({});
  let {
    state: { id },
  } = useLocation<any>();

  //#region GraphQL Query Declaration

  const BUSCAR_COLABORADOR = gql`
    query buscarProfissional($Id: ID!) {
      buscarProfissional(Id: $Id) {
        Id
        IdFranquia
        Sequencial
        Nome
        CPF
        TelefoneFixo
        TelefoneCelular
        Endereco {
          Cep
          Numero
          Complemento
        }
        Agendamentos {
          Id
          DataHoraInicial
          cliente {
            Id
            firstName
            lastName
            registers {
              sequential
              registerId
              franchisingId
            }
          }
          profissional {
            Sequencial
            Nome
          }
          servico {
            Id
            Nome
            PrecoVendaCliente
          }
        }
      }
    }
  `;
  const { data, loading } = useQuery(BUSCAR_COLABORADOR, {
    variables: {
      Id: id,
    },
  });
  //#endregion

  useEffect(() => {
    if (data) {
      const test = data?.buscarProfissional.Endereco.Cep || 0;

      cepPromise(test).then((result) => {
        setAddress(result);
      });
    }
  }, [data]);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <Col style={ColaboradorStyle.container}>
          <AgendamentoDetalhes
            visible={agendamentoVisble}
            setVisible={setAgendamentoVisible}
            agendamento={agendamento}
            cliente={cliente}
          />
          <Row>
            <Foto />
            <Dados colaborador={data.buscarProfissional} endereco={address} />
          </Row>

          <Agendamentos
            agendamentos={
              data && !loading ? data.buscarProfissional.Agendamentos : []
            }
            loading={loading}
            setAgendamento={setAgendamento}
            setAgendamentoVisible={setAgendamentoVisible}
            colaborador={data.buscarProfissional}
            setCliente={setCliente}
          />
        </Col>
      )}
    </>
  );
}
