import React, { CSSProperties, useState } from "react";
import { Row, Table, Card, Avatar, Empty } from "antd";
import { Link } from "react-router-dom";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { getLoggedUser } from "../../common/util";
import { UserOutlined } from "@ant-design/icons";

class ColaboradoresStyle {
  static titulo: CSSProperties = {
    fontSize: 50,
  };
  static table: CSSProperties = {
    width: "100%",
  };
}

export default function Colaboradores() {
  //#region Props and States
  const loggedUser = getLoggedUser();
  const franchise = loggedUser.franchises.map((f) => f.franchisingId).shift();
  const [franchisingId] = useState(franchise);
  const [limit] = useState(10);
  const [offset, setOffset] = useState(0);
  //#endregion

  //#region GraphQL Declarations buscarColaboradoresPorFranquia
  const BUSCAR_COLABORADORES_POR_FRANQUIA = gql`
    query buscarColaboradoresPorFranquia(
      $franchisingId: ID!
      $limit: Int!
      $offset: Int!
    ) {
      buscarColaboradoresPorFranquia(
        franchisingId: $franchisingId
        limit: $limit
        offset: $offset
      ) {
        qty
        profissionais {
          Sequencial
          Id
          Nome
          TelefoneFixo
          TelefoneCelular
          CPF
          Endereco {
            Cep
            Numero
            Complemento
          }
        }
      }
    }
  `;
  const { data, loading } = useQuery(BUSCAR_COLABORADORES_POR_FRANQUIA, {
    variables: {
      franchisingId,
      limit,
      offset,
    },
  });
  //#endregion

  return (
    <Card title={<h1>Colaboradores</h1>}>
      <Row>
        <Table
          style={ColaboradoresStyle.table}
          dataSource={
            !loading && data
              ? data.buscarColaboradoresPorFranquia.profissionais
              : []
          }
          pagination={{
            total:
              !loading && data ? data.buscarColaboradoresPorFranquia.qty : 0,
            onChange: (page, pageSize = 0) => {
              setOffset(page * pageSize - limit);
            },
          }}
          loading={loading}
          locale={{
            emptyText: (
              <Empty
                description={
                  <p style={{ margin: "12px" }}>
                    Nenhum colaborador cadastrado
                  </p>
                }
              />
            ),
          }}
        >
          <Table.Column
            dataIndex="Foto"
            render={(imageProfile) => {
              return (
                <Avatar size={52} src={imageProfile} icon={<UserOutlined />} />
              );
            }}
          />
          <Table.Column title="Sequencial" dataIndex="Sequencial" />
          <Table.Column title="Nome" dataIndex="Nome" />
          <Table.Column title="Fixo" dataIndex="TelefoneFixo" />
          <Table.Column title="Celular" dataIndex="TelefoneCelular" />
          <Table.Column title="CPF" dataIndex="CPF" />
          <Table.Column
            render={(record) => (
              <Link
                to={{
                  pathname: `${
                    process.env.PUBLIC_URL
                  }/main/registers/collaborators/${record.Id.toLowerCase()}`,
                  state: {
                    id: record.Id,
                  },
                }}
              >
                Ver
              </Link>
            )}
          />
        </Table>
      </Row>
    </Card>
  );
}
