import React from "react";
import { Row, Table, notification, Tag, Card, Empty } from "antd";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { currencyFormater, getLoggedUser } from "../../common/util";
import "./Unidades.css";
import Loading from "../../components/Loading/Loading";

function Unidades() {
  //#region GraphQL Declarations

  const FRANQUIA_QUERY = gql`
    query franquia($filtro: FranquiaFiltro!) {
      franquia(filtro: $filtro) {
        Id
        unidades {
          Id
          Nome
          TelefoneFixo
          TelefoneCelular
          FaturamentoMensal
          Endereco {
            cep
            rua
            numero
            bairro
            complemento
          }
          Status
        }
      }
    }
  `;

  const loggedUser = getLoggedUser();
  const franchiseId = loggedUser.franchises.map((f) => f.franchisingId);
  const { loading, error, data } = useQuery(FRANQUIA_QUERY, {
    variables: { filtro: { Id: franchiseId[0] } },
  });
  //#endregion

  //#region Local Components

  function UnitList() {
    const {
      franquia: { unidades },
    } = data || {};

    return (
      <Card title={<h1>Unidades</h1>}>
        <Row>
          <Table
            className="tabela-unidades"
            dataSource={unidades || []}
            rowKey="Id"
            locale={{
              emptyText: (
                <Empty
                  description={
                    <p style={{ margin: "12px" }}>
                      Não ha unidades para exibir
                    </p>
                  }
                />
              ),
            }}
          >
            <Table.Column
              title="Nome"
              dataIndex="Nome"
              render={(text, record: any) => (
                <Link
                  to={{
                    pathname: `${
                      process.env.PUBLIC_URL
                    }/main/registers/franchise/units/${record.Id.toLowerCase()}`,
                    state: {
                      id: record.Id,
                    },
                  }}
                >
                  {record.Nome}
                </Link>
              )}
            />
            <Table.Column
              title="Endereço"
              dataIndex="Endereco"
              render={(endereco) =>
                endereco && endereco.rua && endereco.numero ? (
                  <>{`${endereco.rua}, ${endereco.numero}`}</>
                ) : (
                  <>N/A</>
                )
              }
            />
            <Table.Column title="Telefone" dataIndex="TelefoneFixo" />
            <Table.Column title="Colaboradores" dataIndex="colaboradores" />
            <Table.Column title="Agendamentos" dataIndex="agendamentos" />
            <Table.Column
              title="Faturamento(mês)"
              dataIndex="FaturamentoMensal"
              render={(faturamento) => (
                <h4 className="preco">
                  {currencyFormater(faturamento, "BRL")}
                </h4>
              )}
            />
            <Table.Column
              title="Status"
              dataIndex="Status"
              render={(text: React.ReactNode) => (
                <Tag color={text === 1 ? "green" : "red"}>
                  {text === 1 ? "Online" : "Offline"}
                </Tag>
              )}
            />
          </Table>
        </Row>
      </Card>
    );
  }

  //#endregion

  return (
    <>
      {loading ? (
        <Loading />
      ) : error ? (
        notification.error({
          message: "Erro Inesperado",
          description: error.message,
          duration: 0,
        })
      ) : (
        <UnitList />
      )}
    </>
  );
}

export default Unidades;
