import React, { useState } from "react";
import { Table, Card, Tag, Empty, Button, Popconfirm } from "antd";
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import moment from "moment";
import {
  CheckCircleOutlined,
  SyncOutlined,
  ClockCircleOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";

export default function Notificacoes() {
  const NOTIFICATION_TASKS = gql`
    query notificationTasks {
      notificationTasks {
        id
        name
        description
        status
        category
        executions {
          start
          end
        }
      }
    }
  `;

  const CANCEL_TASK = gql`
    mutation cancelNotificationTask($taskId: ID!) {
      cancelNotificationTask(taskId: $taskId) {
        id
        name
        status
      }
    }
  `;

  const [
    cancelTask,
    { data: cancelData, error, loading: loadingCancel },
  ] = useMutation(CANCEL_TASK);
  const { data, loading } = useQuery(NOTIFICATION_TASKS, {
    pollInterval: 5000,
  });
  const [selectedTask, setSelectedTask] = useState(null);
  const categoryOptions = [
    {
      value: 1,
      label: "Promoções",
    },
    {
      value: 2,
      label: "Avisos",
    },
    {
      value: 3,
      label: "Novidades",
    },
  ];

  return (
    <div>
      <Card
        title="Programações de Envio"
        extra={
          <div>
            <Link to="/app/main/notifications/new">
              <Button size="middle" type="primary">
                <PlusCircleOutlined />
                PROGRAMAR ENVIO
              </Button>
            </Link>
          </div>
        }
      >
        <Table
          bordered={true}
          style={{ minHeight: 500 }}
          dataSource={data?.notificationTasks ? data?.notificationTasks : []}
          loading={loading}
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="Nenhuma programação foi feita até o momento..."
              />
            ),
          }}
          key="id"
          columns={[
            {
              title: "Campanha",
              dataIndex: "name",
            },
            {
              title: "Descrição",
              dataIndex: "description",
            },
            {
              dataIndex: "category",
              title: "Categoria",
              render: (value) =>
                categoryOptions
                  .filter((category) => category.value === value)
                  .shift()?.label,
            },
            {
              title: "Status",
              dataIndex: "status",
              render: (value: NotificationTaskStatus) =>
                value === NotificationTaskStatus.scheduled ? (
                  <Tag icon={<ClockCircleOutlined />} color="default">
                    AGENDADO
                  </Tag>
                ) : value === NotificationTaskStatus.processing ? (
                  <Tag icon={<SyncOutlined spin />} color="processing">
                    PROCESSANDO
                  </Tag>
                ) : value === NotificationTaskStatus.finished ? (
                  <Tag icon={<CheckCircleOutlined />} color="success">
                    FINALIZADO
                  </Tag>
                ) : (
                  <Tag icon={<MinusCircleOutlined />} color="default">
                    CANCELADO
                  </Tag>
                ),
            },
            {
              title: "Data",
              dataIndex: "executions",
              render: (value) =>
                value?.map((i) => (
                  <p className="table-status">
                    <ClockCircleOutlined />
                    {` ${moment(parseInt(i.start)).format(
                      "DD/MM/YYYY HH:mm"
                    )}h até ${moment(parseInt(i.end)).format(
                      "DD/MM/YYYY HH:mm"
                    )}h`}
                  </p>
                )),
            },

            {
              title: "Ações",
              dataIndex: "id",
              width: 160,
              render: (value, record) => (
                <div
                  style={{ justifyContent: "space-between", display: "flex" }}
                >
                  <Link
                    style={{
                      marginRight: 10,
                      textAlign: "center",
                      fontSize: 12,
                    }}
                    to={{
                      pathname: `${process.env.PUBLIC_URL}/main/notifications/${value}/status`,
                      state: {
                        id: value,
                      },
                    }}
                  >
                    ACESSAR
                  </Link>
                  <Popconfirm
                    placement="left"
                    title="Tem certeza que deseja cancelar o processo de envio ?"
                    okText="Sim"
                    onConfirm={() =>
                      cancelTask({
                        variables: {
                          taskId: record.id,
                        },
                      })
                    }
                    cancelText="Não"
                  >
                    <a type="link" style={{ fontSize: 12, color: "red" }}>
                      CANCELAR
                    </a>
                  </Popconfirm>
                </div>
              ),
            },
          ]}
        />
      </Card>
    </div>
  );
}

enum NotificationTaskStatus {
  scheduled = 1,
  processing = 2,
  finished = 3,
  canceled = 4,
}
