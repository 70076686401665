import { AsYouType } from "libphonenumber-js";

const parseDigits = (string) =>
  string ? (string.match(/\d+/g) || []).join("") : "";

const maskPhone = (string) => {
  const digits = parseDigits(string).substr(0, 11);
  return new AsYouType("BR").input(digits);
};

function currencyFormater(value: number, currency: string) {
  const formater = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency,
  });
  return formater.format(value);
}

function RequiredAuth(props) {
  let userLogged = getLoggedUser();
  if (!userLogged) {
    props.history.push("/");
  }
}

function getLoggedUser() {
  let userLogged = localStorage.getItem("loggedUser");
  if (userLogged) {
    return JSON.parse(userLogged);
  } else {
    return null;
  }
}
export { currencyFormater, RequiredAuth, getLoggedUser, maskPhone };
