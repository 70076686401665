import React from "react";
import { Row, Col } from "antd";

import AgendamentosMensais from "../../components/Charts/Clientes/AgendamentosMensais";
import ServicosMaisConsumidos from "../../components/Charts/Clientes/ServicosMaisConsumidos";
import AgendamentosPorStatus from "../../components/Charts/Clientes/AgendamentosPorStatus";
import Clientes from "../Clientes/Clientes";

export default function Dashboard() {
  return (
    <div>
      <Row>
        <Col span={7}>
          <ServicosMaisConsumidos />
        </Col>
        <Col span={17}>
          <Row>
            <Col span={12}>
              <AgendamentosPorStatus />
            </Col>
            <Col span={12}>
              <AgendamentosMensais />
            </Col>
          </Row>
          <Row>
            <Col span={24} style={{ marginTop: "5px", borderRadius: "4px" }}>
              <div
                style={{
                  paddingTop: "5px",
                  height: " 500px",
                  overflow: "auto",
                }}
              >
                <Clientes />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
