import React from "react";

import "./Footer.css";

export default function Footer() {
  const date = new Date();
  return (
    <div className="footer-container">
      <p className="footer-info">{`Copyright © ${date.getFullYear()} Fox Beauty App - Todos os direitos reservados`}</p>
    </div>
  );
}
