import React from "react";
import "./App.less";
import Routes from "./routes";
import { ApolloProvider } from "@apollo/react-hooks";
import { ApolloClient, InMemoryCache } from "apollo-boost";
import { setContext } from "apollo-link-context";
import { createUploadLink } from "apollo-upload-client";
import { getLoggedUser } from "./common/util";

const authLink = setContext(async (_, { headers }) => {
  let loggedUser = await getLoggedUser();

  if (loggedUser && loggedUser.token) {
    return {
      headers: {
        ...headers,
        apptoken: process.env.REACT_APP_API_TOKEN,
        authorization: loggedUser.token ? `Bearer ${loggedUser.token}` : "",
      },
    };
  } else {
    return {
      headers: {
        ...headers,
        apptoken: process.env.REACT_APP_API_TOKEN,
        // Link User Token
      },
    };
  }
});

const httpLink = createUploadLink({
  uri: process.env.REACT_APP_API_URL,
  credentials: "same-origin",
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

function App() {
  return (
    <div className="container-app">
      <ApolloProvider client={client}>
        <Routes />
      </ApolloProvider>
    </div>
  );
}

export default App;
