import React from "react";
import { Chart, Interval, Axis, Coordinate, Legend } from "bizcharts";
import DataSet from "@antv/data-set";

import { Statistic, Skeleton } from "antd";
import { getLoggedUser } from "../../../common/util";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

export default function AgendamentosPorStatus() {
  //#region GraphQL Declaration
  const QTY_AGENDAMENTOS_MES = gql`
    query totalAgendamentos($franchisingId: ID!) {
      totalAgendamentos(franchisingId: $franchisingId) {
        total
        cancelados
        agendados
      }
    }
  `;

  const loggedUser = getLoggedUser();
  const franchisingId = loggedUser.franchises
    .map((f) => f.franchisingId)
    .shift();
  const { loading, data } = useQuery(QTY_AGENDAMENTOS_MES, {
    variables: {
      franchisingId,
    },
    pollInterval: 30000,
  });
  //#endregion

  const ds: any = new DataSet();
  const dv = ds.createView().source([data?.totalAgendamentos] || []);
  dv.transform({
    type: "fold",
    fields: ["agendados", "cancelados"],
    key: "type",
    value: "value",
  });

  return (
    <>
      {!loading && data ? (
        <div
          style={{
            background: "white",
            marginRight: "10px",
            minHeight: 330,
            padding: "25px",
            borderRadius: "3px",
          }}
        >
          <Statistic
            title="Agendamentos via App"
            value={data?.totalAgendamentos.total}
            valueStyle={{ fontSize: "32px" }}
            style={{ marginBottom: "25px" }}
          />
          <Chart height={150} data={dv} autoFit>
            <Legend />
            <Coordinate transpose scale={[1, -1]} />
            <Axis
              label={{
                offset: 12,
              }}
            />
            <Axis name="value" position={"right"} />

            <Interval
              color={{
                fields: ["type"],
                values: ["#62daab", "#e91e63"],
              }}
              position="label*value"
              adjust={[
                {
                  type: "dodge",
                  marginRatio: 1 / 32,
                },
              ]}
            />
          </Chart>
        </div>
      ) : (
        <div
          style={{ background: "white", padding: "25px", marginRight: "10px" }}
        >
          <Skeleton
            active
            paragraph={{
              rows: 7,
            }}
          />
        </div>
      )}
    </>
  );
}
