import React, { useState, useRef } from "react";
import cep from "cep-promise";
import gql from "graphql-tag";
import moment from "moment";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { useLocation, Link } from "react-router-dom";
import {  InfoCircleOutlined } from "@ant-design/icons";
import Loading from "../../components/Loading/Loading";
import {
  Row,
  Col,
  TimePicker,
  Input,
  Form,
  Button,
  Card,
  Modal,
  Upload,
  Table,
  Checkbox,
  message,
  Avatar,
  Tooltip,
} from "antd";
import locale from "antd/es/date-picker/locale/pt_BR";
import "../../App.less";
import ImgCrop from "antd-img-crop";
import {
  PlusOutlined,
  UserOutlined,
  WhatsAppOutlined,
} from "@ant-design/icons";
import { RcFile } from "antd/lib/upload";

function getBase64(file: File): Promise<any> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

function IntervaloDia({ description, day, valid = 0, index }) {
  const [validValue] = useState(valid === 1 ? true : false);
  return (
    <Col key={day.Dia}>
      <Row>
        <h3>{description}</h3>
      </Row>
      <Row>
        <Form.Item
          name={["IntervaloFuncionamento", index]}
          noStyle
          initialValue={[moment(day.start), moment(day.end)]}
        >
          <TimePicker.RangePicker
            name={description}
            locale={locale}
            picker="time"
            placeholder={["Início", "Fim"]}
            style={{ width: "90%" }}
          />
        </Form.Item>
        <Form.Item
          initialValue={validValue}
          noStyle
          name={["HorarioHabilitado", index, "Valido"]}
          valuePropName="checked"
        >
          <Checkbox style={{ marginTop: 5, marginLeft: 15 }} />
        </Form.Item>
      </Row>
    </Col>
  );
}

function IntervaloSemana({ days }) {
  const descriptionsDays = [
    "Segunda",
    "Terça",
    "Quarta",
    "Quinta",
    "Sexta",
    "Sábado",
    "Domingo",
  ];

  if (!days) {
    days = [];
    for (let index = 0; index < 7; index++) {
      const day = {
        Dia: index,
        Inicio: 1576152000000,
        Fim: 1576184400000,
        Valido: 1,
      };
      days.push(day);
    }
  }
  return (
    <Card
      title="Horário de funcionamento"
      headStyle={{ fontSize: "14px" }}
      style={{ borderColor: "#ddd", marginTop: "5px", paddingBottom: "130px" }}
    >
      <Col>
        {days.map((day) => (
          <IntervaloDia
            description={descriptionsDays[day.Dia]}
            index={day.Dia}
            day={{ start: parseInt(day.Inicio), end: parseInt(day.Fim) }}
            valid={day.Valido}
          />
        ))}
      </Col>
    </Card>
  );
}

function UploadBanners({ unidade }) {
  //#region GraphQL Mutation
  const UPLOAD_PHOTO = gql`
    mutation uploadBannerUnidade($file: Upload!, $unidadeId: ID!) {
      uploadBannerUnidade(file: $file, unidadeId: $unidadeId) {
        url
      }
    }
  `;
  const [uploadBannerUnidade] = useMutation(UPLOAD_PHOTO);

  const REMOVER_BANNER = gql`
    mutation removerBannerUnidade($banners: [BannerInput!]!, $unidadeId: ID!) {
      removerBannerUnidade(banners: $banners, unidadeId: $unidadeId) {
        Nome
      }
    }
  `;
  const [removerBannerUnidade] = useMutation(REMOVER_BANNER);

  //#endregion

  const { Banners } = unidade || [];

  for (const banner of Banners) {
    banner.uid = Math.random();
    banner.name = "Test";
    banner.status = "done";
  }

  //#region States and Params
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileUploadList, setFileUploadList] = useState(Banners);
  //#endregion
  const handleChange = ({ file, fileList, event }: any) => {
    const isLt1M = file.size / 1024 / 1024 < 1;
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";

    if (isJpgOrPng && isLt1M && fileUploadList.length <= 5) {
      return setFileUploadList(fileList);
    } else {
      return;
    }
  };
  const handleCancel = () => setPreviewVisible(false);
  const handlePreview = async (file: any) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  function beforeUpload(file: RcFile) {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    const isLt2M = file.size / 1024 / 1024 < 2;
    const uploadLimit = fileUploadList.length <= 3;

    if (!isJpgOrPng) {
      message.error("São permitidos somente arquivos de imagem!");
    }
    if (!isLt2M) {
      message.error("O tamanho do banner é limitado em 1MB!");
    }
    if (!uploadLimit) {
      message.error("Uma unidade pode ter até 3 banners!");
    }
    return isJpgOrPng && isLt2M && uploadLimit;
  }

  return (
    <div className="clearfix">
      <ImgCrop aspect={16 / 9}>
        <Upload
          accept=".jpg,.png,.jpeg"
          listType="picture-card"
          fileList={fileUploadList}
          onPreview={handlePreview}
          beforeUpload={beforeUpload}
          onChange={handleChange}
          onRemove={(file) => {
            const files = fileUploadList.filter((f) => f.uid !== file.uid);
            const banners = files.map((file) => {
              return { url: file.url };
            });

            removerBannerUnidade({
              variables: {
                banners,
                unidadeId: unidade.Id,
              },
            })
              .then(({ data: { removerBannerUnidade } }) => {
                message.success(
                  `Banner selecionado foi removido da unidade ${removerBannerUnidade.Nome}`
                );
                setFileUploadList(files);
              })
              .catch((error) => {
                message.error(error.message);
              });
          }}
          customRequest={async ({ file }) => {
            if (fileUploadList.length < 5) {
              uploadBannerUnidade({
                variables: {
                  file: file,
                  unidadeId: unidade.Id,
                },
              })
                .then(({ data: { uploadBannerUnidade } }) => {
                  message.success("Upload Concluído");
                  setFileUploadList([
                    ...fileUploadList,
                    {
                      uid: Math.random(),
                      name: file.name,
                      url: uploadBannerUnidade.url,
                      status: "done",
                    },
                  ]);
                })
                .catch((error) => {
                  message.error(error.message.substring(14));
                  setFileUploadList([]);
                });
            } else {
              setFileUploadList([
                ...fileUploadList,
                {
                  uid: Math.random(),
                  name: file.name,
                  status: "error",
                  url: "",
                },
              ]);
            }
          }}
        >
          {fileUploadList.length >= 5 ? null : uploadButton}
        </Upload>
      </ImgCrop>
      <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </div>
  );
}

function Banners({ unidade }) {
  return (
    <Card
      title={
                <div
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <p style={{ paddingTop: 10 }}>Banners</p>
                  <Tooltip
                    title={`-> Banners deverão ser no aspect ratio 4:3 (quadrado), com resolução mínima de 400x400.`}
                  >
                    <InfoCircleOutlined style={{ color: "#0693E3" }} />
                  </Tooltip>
                </div>
              }
      headStyle={{ fontSize: "14px" }}
      style={{
        borderColor: "#ddd",
        margin: "5px",
        paddingBottom: "18px",
      }}
    >
      <UploadBanners unidade={unidade} />
    </Card>
  );
}

function Colaboradores() {
  let {
    state: { id },
  } = useLocation<any>();
  //#region GraphQL Query Declaration
  const BUSCAR_COLABORADORES_POR_UNIDADE = gql`
    query buscarColaboradoresPorUnidade($unitId: ID!) {
      buscarColaboradoresPorUnidade(unitId: $unitId) {
        Id
        Sequencial
        Nome
        TelefoneFixo
        TelefoneCelular
        CPF
      }
    }
  `;
  const { data, loading } = useQuery(BUSCAR_COLABORADORES_POR_UNIDADE, {
    variables: {
      unitId: id,
    },
  });
  //#endregion

  return (
    <Col>
      <Table
        dataSource={!loading && data ? data.buscarColaboradoresPorUnidade : []}
        loading={loading}
      >
        <Table.Column
          dataIndex="Foto"
          render={(foto) => (
            <Avatar size={52} src={foto} icon={<UserOutlined />} />
          )}
        />
        <Table.Column title="Sequencial" dataIndex="Sequencial" />
        <Table.Column title="Nome" dataIndex="Nome" />
        <Table.Column title="Fixo" dataIndex="TelefoneFixo" />
        <Table.Column title="Celular" dataIndex="TelefoneCelular" />
        <Table.Column title="CPF" dataIndex="CPF" />
        <Table.Column
          render={(record) => (
            <Link
              to={{
                pathname: `${
                  process.env.PUBLIC_URL
                }/main/registers/collaborators/${record.Id.toLowerCase()}`,
                state: {
                  id: record.Id,
                },
              }}
            >
              Ver
            </Link>
          )}
        />
      </Table>
    </Col>
  );
}

export default function Unidade() {
  //#region State and Params declarations
  const { innerWidth: width } = window;
  let {
    state: { id },
  } = useLocation<any>();

  const [key, setKey] = useState("tab1");
  const tabs = [
    {
      key: "tab1",
      tab: "Gerais",
    },
    {
      key: "tab2",
      tab: "Colaboradores",
    },
  ];
  const contentList: any = {
    tab1: <Dados />,
    tab2: <Colaboradores />,
  };

  //#endregion

  //#region GraphQL Declarations

  const UNIDADE_QUERY = gql`
    query unidade($unidade: UnidadeFiltro!) {
      unidade(unidade: $unidade) {
        Id
        Nome
        Endereco {
          cep
          rua
          numero
          bairro
          complemento
          cidade
          estado
        }
        TelefoneFixo
        TelefoneCelular
        WhatsAppFixo
        WhatsAppCelular
        Instagram
        Facebook
        Logo
        Ativo
        Banners {
          url
        }
        HorarioFuncionamento {
          Dia
          Inicio
          Fim
          Valido
        }
        configSms {
          smsAccessKeyId
          smsSecretAccessKey
        }
      }
    }
  `;
  const { loading, data } = useQuery(UNIDADE_QUERY, {
    variables: {
      unidade: { Id: id },
    },
  });

  //#endregion

  function Dados() {
    let formRef = useRef<any>();

    const unidade = data.unidade;

    //#region GraphQL Mutation

    const ATUALIZAR_UNIDADE = gql`
      mutation atualizarUnidade($unidade: UnidadeInput!) {
        atualizarUnidade(unidade: $unidade) {
          Nome
        }
      }
    `;
    const [atualizarUnidade] = useMutation(ATUALIZAR_UNIDADE);
    //#endregion

    function onFinish(values) {
      let whatsAppFixo = 0;
      let whatsAppCelular = 0;
      const {
        configSms,
        IntervaloFuncionamento,
        HorarioHabilitado,
        TelefoneCelular,
        TelefoneFixo,
        Nome,
        rua,
        estado,
        bairro,
        cep,
        cidade,
        numero,
        complemento,
        Instagram,
        Facebook,
        WhatsAppFixo,
        WhatsAppCelular,
      } = values;
      console.log(WhatsAppFixo);

      whatsAppCelular = WhatsAppCelular ? 1 : 0;
      whatsAppFixo = WhatsAppFixo ? 1 : 0;
      let HorarioFuncionamento: any = [];

      for (let index = 0; index < IntervaloFuncionamento.length; index++) {
        const obj: any = {
          Dia: index,
          Inicio: IntervaloFuncionamento[index][0].format("x"),
          Fim: IntervaloFuncionamento[index][1].format("x"),
          Valido: HorarioHabilitado[index].Valido ? 1 : 0,
        };
        HorarioFuncionamento.push(obj);
      }
      console.log(whatsAppFixo);

      const unidadeRequest = {
        idUnit: unidade.Id,
        Nome,
        Instagram,
        Facebook,
        WhatsAppCelular: whatsAppCelular,
        WhatsAppFixo: whatsAppFixo,
        Endereco: {
          rua,
          estado,
          bairro,
          cep,
          cidade,
          numero,
          complemento,
        },
        configSms,
        HorarioFuncionamento,
        TelefoneFixo,
        TelefoneCelular,
      };

      atualizarUnidade({
        variables: {
          unidade: unidadeRequest,
        },
      })
        .then(({ data: { atualizarUnidade } }) => {
          message.success(`Unidade ${atualizarUnidade.Nome} foi atualizada!`);
        })
        .catch((onerror) => {
          message.error(onerror.message);
        });
    }
    console.log(width);

    return (
      <Form
        name="complex-form"
        onFinish={onFinish}
        ref={formRef}
        labelCol={{ span: 3 }}
      >
        <Row>
          <Col span={16}>
            <Row>
              <Col md={24}>
                <Card
                  title="Informações gerais"
                  headStyle={{ fontSize: "14px" }}
                  style={{ margin: "5px", borderColor: "#ddd" }}
                >
                  <Form.Item>
                    <Input.Group compact>
                      <Form.Item
                        name="Nome"
                        style={{ minWidth: "50%", paddingRight: "5px" }}
                        initialValue={unidade.Nome}
                        rules={[
                          {
                            required: true,
                            message: " ",
                          },
                        ]}
                      >
                        <Input placeholder="Nome" />
                      </Form.Item>
                      <Form.Item
                        name="TelefoneFixo"
                        style={{
                          width: width >= 1600 ? "21%" : "19%",
                          paddingRight: "5px",
                        }}
                        initialValue={unidade.TelefoneFixo}
                        rules={[
                          {
                            required: true,
                            message: " ",
                          },
                        ]}
                      >
                        <Input placeholder="Telefone" />
                      </Form.Item>
                      <Form.Item
                        initialValue={unidade.WhatsAppFixo === 1 ? true : false}
                        name="WhatsAppFixo"
                        valuePropName="checked"
                      >
                        <Checkbox>
                          <WhatsAppOutlined style={{ color: "#00e676" }} />
                        </Checkbox>
                      </Form.Item>

                      <Form.Item
                        name="TelefoneCelular"
                        style={{
                          width: width >= 1600 ? "21%" : "19%",
                          paddingRight: "5px",
                        }}
                        initialValue={unidade.TelefoneCelular}
                      >
                        <Input placeholder="Celular" />
                      </Form.Item>
                      <Form.Item
                        initialValue={
                          unidade.WhatsAppCelular === 1 ? true : false
                        }
                        name="WhatsAppCelular"
                        valuePropName="checked"
                      >
                        <Checkbox>
                          <WhatsAppOutlined style={{ color: "#00e676" }} />
                        </Checkbox>
                      </Form.Item>

                      <Form.Item
                        style={{ width: "50%", paddingRight: "5px" }}
                        name="Facebook"
                        initialValue={unidade?.Facebook}
                      >
                        <Input placeholder="ID Página Facebook" />
                      </Form.Item>
                      <Form.Item
                        style={{ width: "50%" }}
                        name="Instagram"
                        initialValue={unidade?.Instagram}
                      >
                        <Input placeholder="Instagram" addonBefore="@" />
                      </Form.Item>
                    </Input.Group>
                  </Form.Item>
                  <Row>
                    <Col span={24}>
                      <Form.Item>
                        <Input.Group compact>
                          <Form.Item
                            name="cep"
                            style={{ width: "20%", paddingRight: "5px" }}
                            initialValue={
                              unidade.Endereco ? unidade.Endereco.cep : ""
                            }
                            rules={[
                              {
                                required: true,
                                message: " ",
                                max: 9,
                                min: 9,
                                pattern: /^\d{5}-\d{3}$/g,
                              },
                            ]}
                          >
                            <Input
                              placeholder="CEP"
                              onChange={(event) => {
                                const cepValue = event.target.value;
                                if (cepValue.length === 9) {
                                  cep(cepValue).then((response) => {
                                    formRef.current.setFieldsValue({
                                      estado: response.state,
                                      rua: response.street,
                                      bairro: response.neighborhood,
                                      cidade: response.city,
                                    });
                                  });
                                }
                              }}
                            />
                          </Form.Item>
                          <Form.Item
                            shouldUpdate
                            name="rua"
                            initialValue={
                              unidade.Endereco ? unidade.Endereco.rua : ""
                            }
                            noStyle
                            rules={[
                              {
                                required: true,
                                message: " ",
                              },
                            ]}
                          >
                            <Input style={{ width: "80%" }} placeholder="Rua" />
                          </Form.Item>
                        </Input.Group>
                        <Input.Group compact style={{ marginBottom: 10 }}>
                          <Form.Item
                            name="bairro"
                            style={{ width: "50%", paddingRight: "5px" }}
                            rules={[{ required: true, message: " " }]}
                            initialValue={
                              unidade.Endereco ? unidade.Endereco.bairro : ""
                            }
                          >
                            <Input placeholder="Bairro" />
                          </Form.Item>
                          <Form.Item
                            name="cidade"
                            style={{ width: "25%", paddingRight: "5px" }}
                            rules={[{ required: true, message: " " }]}
                            initialValue={
                              unidade.Endereco ? unidade.Endereco.cidade : ""
                            }
                          >
                            <Input placeholder="Cidade" />
                          </Form.Item>
                          <Form.Item
                            name="estado"
                            noStyle
                            initialValue={
                              unidade.Endereco ? unidade.Endereco.estado : ""
                            }
                            rules={[
                              {
                                required: true,
                                message: " ",
                              },
                            ]}
                          >
                            <Input
                              style={{ width: "12.5%" }}
                              placeholder="Estado"
                            />
                          </Form.Item>
                          <Form.Item
                            shouldUpdate
                            name="numero"
                            style={{ width: "12.5%", paddingLeft: "5px" }}
                            initialValue={
                              unidade.Endereco ? unidade.Endereco.numero : ""
                            }
                            rules={[
                              {
                                required: true,
                                message: " ",
                              },
                            ]}
                          >
                            <Input placeholder="Número" />
                          </Form.Item>
                        </Input.Group>
                        <Form.Item
                          name="complemento"
                          noStyle
                          initialValue={
                            unidade.Endereco ? unidade.Endereco.complemento : ""
                          }
                        >
                          <Input placeholder="Adicional" />
                        </Form.Item>
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>
              </Col>

              <Col md={12}>
                <Card
                  title={
                <div
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <p style={{ paddingTop: 10 }}>Configuração envio SMS</p>
                  <Tooltip
                    title={`-> O cliente deverá possuir uma conta da AWS e habilitar o serviço SNS, com estas condições atendias, será necessário criar uma credencial no IAM, com as permissões de acesso ao SNS, ao criar a credencial, duas informações serão geradas, ACCESS_KEY e SECRET_ACCESS_KEY, ACCESS_KEY será o campo “Chave” e SECRET_KEY o campo “Segredo” na Configuração envio SMS.`}
                  >
                    <InfoCircleOutlined style={{ color: "#0693E3" }} />
                  </Tooltip>
                </div>
              }
                  headStyle={{ fontSize: "14px" }}
                  style={{
                    margin: "5px",
                    borderColor: "#ddd",
                  }}
                >
                  <Form.Item>
                    <Form.Item
                      name={["configSms", "smsAccessKeyId"]}
                      rules={[
                        {
                          required: true,
                          message: " ",
                        },
                      ]}
                      initialValue={
                        unidade.configSms
                          ? unidade.configSms.smsAccessKeyId
                          : ""
                      }
                    >
                      <Input placeholder="Chave" />
                    </Form.Item>
                    <Form.Item
                      name={["configSms", "smsSecretAccessKey"]}
                      rules={[
                        {
                          required: true,
                          message: " ",
                        },
                      ]}
                      initialValue={
                        unidade.configSms
                          ? unidade.configSms.smsSecretAccessKey
                          : ""
                      }
                    >
                      <Input placeholder="Segredo" />
                    </Form.Item>
                  </Form.Item>
                </Card>
              </Col>
              <Col span={12}>
                <Banners unidade={unidade} />
              </Col>
            </Row>
          </Col>
          <Col sm={8}>
            <IntervaloSemana days={unidade.HorarioFuncionamento} />
          </Col>

          <Col span={16}></Col>
          <Col span={8} style={{ textAlign: "end", marginTop: "30px" }}>
            <Button
              type="primary"
              size="large"
              style={{ marginRight: "5px" }}
              htmlType="submit"
            >
              Salvar
            </Button>
            <Button type="default" size="large">
              Cancelar
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <Card
          title={<h1>Atualizar Unidade</h1>}
          style={{
            boxShadow: "5px 10px 18px #ddd",
            backgroundColor: "#fbfbfb",
          }}
          tabList={tabs}
          activeTabKey={key}
          onTabChange={(key) => setKey(key)}
        >
          {contentList[key]}
        </Card>
      )}
    </div>
  );
}
